import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [cart, setCart] = useState([]);
  const [deliveryOption, setDeliveryOption] = useState('store');
  const [origin, setOrigin] = useState('');
  const [company, setCompany] = useState({ zip_code: '', full_address: '' });
  const [clientId, setClientId] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [deliveryEstimate, setDeliveryEstimate] = useState('');
  const [newClient, setNewClient] = useState({
    phone: '',
    name: '',
    email: '',
    zip_code: '',
    rua: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
  });
  const [showRestartModal, setShowRestartModal] = useState(false);

  useEffect(() => {
    const fetchClientData = async () => {
      if (newClient.phone) {
        const matchedClient = clientsList.find(client => client.phone === newClient.phone);
  
        if (matchedClient) {
          setNewClient({
            phone: matchedClient.phone,
            name: matchedClient.name,
            email: matchedClient.email,
            zip_code: matchedClient.zip_code,
          });
          setClientId(matchedClient.id);
  
          try {
            const response = await axios.get(`https://viacep.com.br/ws/${matchedClient.zip_code}/json/`);
            const { logradouro, bairro, localidade, uf } = response.data;
  
            setNewClient(prevClient => ({
              ...prevClient,
              rua: logradouro || '',
              bairro: bairro || '',
              cidade: localidade || '',
              estado: uf || ''
            }));
  
            // Set the delivery estimate message based on localidade
            if (localidade === 'São Paulo') {
              setDeliveryEstimate('Entrega em até 3 dias | frete grátis');
            } else {
              setDeliveryEstimate('Entrega em até 7 dias | frete grátis');
            }
          } catch (error) {
            console.error("Error fetching address data:", error);
          }
        } else {
          setClientId('');
        }
      }
    };
  
    fetchClientData();
  }, [clientsList, newClient.phone]);

  useEffect(() => {
    if (newClient.phone) {
      const matchedClient = clientsList.find(client => client.phone === newClient.phone);
  
      if (matchedClient) {
        setNewClient(prevClient => ({
          ...prevClient,
          phone: matchedClient.phone,
          name: matchedClient.name,
          email: matchedClient.email,
          zip_code: matchedClient.zip_code,
          full_address: matchedClient.full_address,
        }));
        setClientId(matchedClient.id);
      } else {
        if (clientId) setClientId('');
        setNewClient(prevClient => ({
          ...prevClient,
          phone: newClient.phone, // Retain phone
        }));
      }
  
      updateUrlParams({ newClient });
    }
  }, [newClient.phone, clientsList]);

  useEffect(() => {
    if (newClient.name) {
      setNewClient(prevClient => ({
        ...prevClient,
        name: newClient.name
      }));
      updateUrlParams({ newClient });
    }
  }, [newClient.name]);
  
  useEffect(() => {
    if (newClient.email) {
      setNewClient(prevClient => ({
        ...prevClient,
        email: newClient.email
      }));
      updateUrlParams({ newClient });
    }
  }, [newClient.email]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    
    const productIds = searchParams.get('products')?.split(',') || [];
    const companyId = searchParams.get('companyId');
    const catalogSlug = searchParams.get('catalogSlug');
    const deliveryOptionParam = searchParams.get('deliveryOption');
    const originParam = searchParams.get('origin');
  
    if (companyId && catalogSlug && productIds.length > 0) {
      fetchCompanyData(companyId);
      fetchCatalogProductsData(catalogSlug, productIds);
      fetchClientsList(companyId); // Fetch the clients list when the company is loaded

      if(catalogSlug == 'pureskin') setDeliveryOption('address')
    }
  
    if (clientId) {
      fetchClientData(clientId);
    }
  
    if (deliveryOptionParam) setDeliveryOption(deliveryOptionParam);
    if (originParam) setOrigin(originParam);
  
    const newClientParams = {
      phone: searchParams.get('phone') || '',
      name: searchParams.get('name') || '',
      email: searchParams.get('email') || '',
      zip_code: searchParams.get('zip_code') || '',
      full_address: searchParams.get('full_address') || '',
    };
    setNewClient(newClientParams);
  }, [location.search]);

  const fetchCompanyData = async (companyId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/companies/${companyId}`);
      setCompany(response.data);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const fetchCatalogProductsData = async (catalogSlug,productIds) => {
    try {
      const catalogResponse = await axios.get(`${apiBaseUrl}/catalogs/slug/${catalogSlug}`);
      const catalog = catalogResponse.data;

      const productsResponse = await axios.get(`${apiBaseUrl}/products/slug/${catalog.slug}`);
      const products = productsResponse.data;

      const cartItems = productIds.map(productIdQuantity => {
        const [id, quantity] = productIdQuantity.split(':');
        const product = products.find(p => p.id === id);

        if (product) {
          return { ...product, quantity: parseInt(quantity, 10) };
        }
        return null;
      }).filter(item => item !== null);

      setCart(cartItems);
    } catch (error) {
      console.error("Error fetching catalog and products data:", error);
    }
  };

  const fetchClientsList = async (companyId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/clients/${companyId}`);
      setClientsList(response.data); // Store the fetched clients list
    } catch (error) {
      console.error("Error fetching client list:", error);
    }
  };

  const fetchClientData = async (clientId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/clients/${clientId}`);
      setClientId(clientId);
      setNewClient(response.data);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const updateUrlParams = (changes = {}) => {
    const searchParams = new URLSearchParams(location.search);
    
    if (changes.cart) {
      searchParams.set('products', changes.cart.map(item => `${item.id}:${item.quantity}`).join(','));
    }
  
    if (changes.deliveryOption) {
      searchParams.set('deliveryOption', changes.deliveryOption);
    }
  
    if (changes.newClient) {
      Object.keys(changes.newClient).forEach(key => {
        if (changes.newClient[key] !== undefined && changes.newClient[key] !== '') {
          searchParams.set(key, changes.newClient[key]);
        } else {
          searchParams.delete(key);
        }
      });
    }
  
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    setNewClient(prevClient => ({ ...prevClient, phone }));
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setNewClient(prevClient => ({ ...prevClient, name }));
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewClient(prevClient => ({ ...prevClient, email }));
  };

  const handleZipCodeChange = async (e) => {
    const zip = e.target.value;
    
    setNewClient(prevClient => ({ ...prevClient, zip_code: zip }));
  
    if (zip.length >= 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${zip}/json/`);
        const { logradouro, bairro, localidade, uf } = response.data;
        
        setNewClient(prevClient => ({
          ...prevClient,
          rua: logradouro || '',
          bairro: bairro || '',
          cidade: localidade || '',
          estado: uf || ''
        }));
  
        // Set the delivery estimate message based on localidade
        if (localidade === 'São Paulo') {
          setDeliveryEstimate('Entrega em até 3 dias | frete grátis');
        } else {
          setDeliveryEstimate('Entrega em até 7 dias | frete grátis');
        }
  
      } catch (error) {
        console.error("Error fetching address data:", error);
      }
    }
  };

  const handleNumeroChange = (e) => {
    const numero = e.target.value;
    setNewClient(prevClient => ({ ...prevClient, numero }));
  };
  
  const handleComplementoChange = (e) => {
    const complemento = e.target.value;
    setNewClient(prevClient => ({ ...prevClient, complemento }));
  };

  const handleRemoveItem = (index) => {
    setCart(prevCart => {
      if (prevCart.length === 1) {
        setShowRestartModal(true);
        return prevCart;
      } else {
        const updatedCart = prevCart.filter((_, i) => i !== index);
        updateUrlParams({ cart: updatedCart });
        return updatedCart;
      }
    });
  };

  const handleChangeQuantity = (index, amount) => {
    setCart(prevCart => {
      const updatedCart = prevCart.map((item, i) =>
        i === index ? { ...item, quantity: Math.max(1, item.quantity + amount) } : item
      );
      updateUrlParams({ cart: updatedCart });
      return updatedCart;
    });
  };

  const handleDeliveryOptionChange = (option) => {
    setDeliveryOption(option);
    
    const updatedClient = {
      ...newClient,
      zip_code: '',
      full_address: '',
    };

    setNewClient(updatedClient);
  
    updateUrlParams({ deliveryOption: option, newClient: updatedClient });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { rua, numero, complemento, bairro, cidade, estado } = newClient;
    
    const clientData = {
      phone: newClient.phone,
      name: newClient.name,
      email: newClient.email,
      zip_code: deliveryOption === 'store' ? '-' : newClient.zip_code, 
      full_address: deliveryOption === 'store' ? '-' : `${rua || ''}, ${numero || ''}, ${complemento || ''}, ${bairro || ''}, ${cidade || ''} - ${estado || ''}`, 
    };
  
    if (!clientId) {
      try {  
        const clientResponse = await axios.post(`${apiBaseUrl}/clients/${company.id}`, clientData);
        setClientId(clientResponse.data.id);
  
        await createOrder(clientResponse.data.id, deliveryOption === 'store' ? company.full_address : clientData.full_address);
      } catch (error) {
        console.error("Error creating client:", error);
      }
    } else {
      await createOrder(clientId, deliveryOption === 'store' ? company.full_address : clientData.full_address);
    }
  };

  const createOrder = async (clientId, full_address) => {
    const items = cart.map(item => ({
      product_id: item?.id,
      name: item?.name,
      internet_url: item?.internet_url,
      quantity: item?.quantity,
      original_price: item?.original_price,
      price: item?.price,
      discount_percentage: item?.discount_percentage,
    }));

    const orderData = {
      origin,
      description: `Delivery to ${full_address}`,
      items: JSON.stringify(items),
    };

    try {
      const response = await axios.post(`${apiBaseUrl}/orders/${clientId}`, orderData);

      const catalogSlug = location.search.split('catalogSlug=')[1].split('&')[0];
      const restartUrl = `/${origin}/${catalogSlug}`;
      navigate('/payment', { state: { orderId: response.data.id, cart, newClient, company, restartUrl, themeColor, origin } });
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  const calculateTotal = () => {
    return cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
  };

  const handleOpenRestartModal = () => {
    setShowRestartModal(true);
  };

  const handleCloseRestartModal = () => {
    setShowRestartModal(false);
  };

  const catalogSlug = location.search.split('catalogSlug=')[1].split('&')[0];
  const searchParams = new URLSearchParams(location.search);
  const themeColor = searchParams.get('themeColor');

  console.log(newClient);

  return (
    <div className="checkout-container">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 style={{ fontSize: '2.5rem', marginBottom: '20px', marginTop: '20px' }}>Checkout</h1>
        {!(origin == 'selected_shop' || origin == 'shop') && <button
          style={{
            backgroundColor: themeColor || '#333',
            color: '#fff',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1rem',
          }}
          
          onClick={() => {
            const productIds = cart.map(item => `${item.id}:${item.quantity}`).join(',');
            const searchParams = new URLSearchParams();
            searchParams.set('products', productIds);
            searchParams.set('companyId', company.id);
            searchParams.set('catalogSlug', location.search.split('catalogSlug=')[1].split('&')[0]);
            searchParams.set('name', newClient.name);
            searchParams.set('phone', newClient.phone);
            searchParams.set('email', newClient.email);

            const newUrl = `/${origin}/${searchParams.get('catalogSlug')}?${searchParams.toString()}`;
            navigate(newUrl);
          }}
        >
          Voltar à seleção
        </button>}
      </div>
      <div className="checkout-header">
        <div className="header-item header-image"></div>
        <div className="header-item header-name">Name</div>
        <div className="header-item header-quantity">Quantity</div>
        <div className="header-item header-total">Total</div>
        <div className="header-item header-remove"></div>
      </div>

      {cart.length === 0 ? (
        <p>Sem items no carrinho</p>
      ) : (
        <div className="cart-items">
          {cart.map((item, index) => (
            <div key={index} className="cart-item">
              <img src={item.image_urls[0]} alt={item.name} className="cart-item-image" />
              <div className="cart-item-details">{item.name}</div>
              <div className="quantity-control">
                <button type="button" onClick={() => handleChangeQuantity(index, -1)} className="quantity-button">-</button>
                <span>{item.quantity}</span>
                <button type="button" onClick={() => handleChangeQuantity(index, 1)} className="quantity-button">+</button>
              </div>
              <div className="item-total">R$ {(item.price * item.quantity).toFixed(2)}</div>
              <button type="button" className="remove-button" onClick={() => handleRemoveItem(index)}>Remover</button>
            </div>
          ))}
        </div>
      )}

      <div className="cart-total-container">
        <h3 className="cart-total">Total final: R$ {calculateTotal().toFixed(2)}</h3>
        {!(origin == 'selected_shop' || origin == 'shop') && <button className="recomecar-button" onClick={handleOpenRestartModal}>
          <span className="recomecar-text">↺ Recomeçar</span>
        </button>}
      </div>
      <div className="checkout-form">
        <form onSubmit={handleSubmit}>
          <div className="form">
            <h3 style={{ fontSize: '1.5rem', marginBottom: '10px', fontWeight: 'bold' }}>Cliente</h3>
            <input
              type="text"
              placeholder="Telefone (obrigatório)"
              value={newClient.phone}
              onChange={handlePhoneChange}
            />
            <input
              type="text"
              placeholder="Nome (obrigatório)"
              value={newClient.name}
              onChange={handleNameChange}
              disabled={!!clientId}
            />
            <input
              type="email"
              placeholder="Email (obrigatório)"
              value={newClient.email}
              onChange={handleEmailChange}
              disabled={!!clientId}
            />
          </div>
          <h3 style={{ fontSize: '1.5rem', marginBottom: '10px', marginTop: '40px', fontWeight: 'bold' }}>Entrega</h3>
          {catalogSlug != 'pureskin' && <div className="delivery-options">
            <label className="delivery-option">
              <input
                type="radio"
                name="deliveryOption"
                value="store"
                checked={deliveryOption === 'store'}
                onChange={() => handleDeliveryOptionChange('store')}
              />
              Entregar no estabelecimento
            </label>
            <label className="delivery-option">
              <input
                type="radio"
                name="deliveryOption"
                value="address"
                checked={deliveryOption === 'address'}
                onChange={() => handleDeliveryOptionChange('address')}
              />
              Entregar no seu endereço
            </label>
          </div>}
          {deliveryOption === 'address' && (
            <div className="address-fields">
              <input
                type="text"
                placeholder="CEP"
                value={newClient.zip_code}
                onChange={handleZipCodeChange}
              />
              {/* Display delivery estimate next to the ZIP code */}
              {deliveryEstimate && <div className="delivery-estimate">{deliveryEstimate}</div>}
              <input
                type="text"
                placeholder="Rua"
                id="rua"
                name="rua"
                value={newClient.rua}
                readOnly
              />
              <input
                type="text"
                placeholder="Número"
                id="numero"
                name="numero"
                value={newClient.numero}
                onChange={handleNumeroChange}
                required
              />
              <input
                type="text"
                placeholder="Complemento"
                id="complemento"
                name="complemento"
                value={newClient.complemento}
                onChange={handleComplementoChange}
              />
              <input
                type="text"
                placeholder="Bairro"
                id="bairro"
                name="bairro"
                value={newClient.bairro}
                onChange={(e) => setNewClient(prev => ({ ...prev, bairro: e.target.value }))}
              />
              <input
                type="text"
                placeholder="Cidade"
                id="cidade"
                name="cidade"
                value={newClient.cidade}
                readOnly
              />
              <input
                type="text"
                placeholder="Estado"
                id="estado"
                name="estado"
                value={newClient.estado}
                readOnly
              />
            </div>
          )}
          <button type="submit" disabled={(!newClient.phone || !newClient.name || !newClient.email)} style={{ opacity: (!newClient.phone || !newClient.name || !newClient.email) ? 0.6 : 1, cursor: (!newClient.phone || !newClient.name || !newClient.email) ? 'not-allowed' : 'pointer' }} className="checkout-submit-button">Ir para pagamento</button>
        </form>
      </div>

      {showRestartModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseRestartModal}>×</button>
            <p>Deseja remover todos os itens do seu carrinho e recomeçar?</p>
            <div className="modal-actions">
              <button className="cancel-restart" onClick={handleCloseRestartModal}>Não</button>
              <button className="confirm-restart" onClick=
                {() => {
                  setCart([]);
                  const catalogSlug = location.search.split('catalogSlug=')[1].split('&')[0];
                  const restartUrl = `/${origin}/${catalogSlug}`;
                  navigate(restartUrl);
                  setShowRestartModal(false);
                }}>Sim
              </button>
            </div>
          </div>
        </div>
      )}

      <style>{`
        .checkout-container {
          min-height: 100vh;
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          font-family: Arial, sans-serif;
        }
        .checkout-header, .cart-item {
          display: grid;
          grid-template-columns: 80px 2fr 1fr 1fr 1fr;
          align-items: center;
          text-align: center;
          padding: 10px 0;
        }
        .checkout-header {
          border-bottom: 2px solid #ddd;
          font-weight: bold;
        }
        .cart-item {
          border-bottom: 1px solid #ddd;
          padding: 10px 0;
        }
        .cart-item-image {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 5px;
        }
        .header-name, .header-total, .cart-item-details, .item-total {
          font-weight: bold;
        }
        .quantity-control {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .quantity-button {
          padding: 5px 10px;
          font-size: 1rem;
          background-color: transparent;
          color: #333;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
        }
        .quantity-button:hover {
          background-color: #ddd;
        }
        .quantity-control span {
          margin: 0 10px;
          font-size: 1.2rem;
        }
        .remove-button {
          background-color: transparent;
          color: #333;
          border: 1px solid #ddd;
          padding: 5px 10px;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
        }
        .remove-button:hover {
          background-color: #ddd;
        }
        .cart-total-container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 20px;
        }
        .cart-total {
          font-size: 1.5rem;
          font-weight: bold;
        }
        .trash-button {
          background-color: transparent;
          color: #333;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
        }
        .checkout-form {
          margin-top: 40px;
          margin-bottom: 100px;
        }
        .form input {
          display: block;
          width: 95%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .address-fields {
          margin-top: 20px;
        }
        .address-fields input {
          display: block;
          width: 95%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .checkout-submit-button {
          padding: 10px;
          font-size: 1rem;
          background-color: ${themeColor || '#333'};
          color: #fff;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 40px;
          transition: background-color 0.3s, color 0.3s;
          width: 100%;
        }
        .checkout-submit-button:hover {
          background-color: ${themeColor || '#333'}70;
        }
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .modal-content {
          background-color: #fff;
          padding: 20px;
          border-radius: 5px;
          max-width: 400px;
          width: 100%;
          position: relative;
        }
        .close-modal {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 1.5rem;
          background: none;
          border: none;
          cursor: pointer;
        }
        .modal-actions {
          display: flex;
          justify-content: center;
          gap: 15px;
          margin-top: 20px;
        }
        .cancel-restart {
          background-color: #e0e0e0;
          color: black;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .confirm-restart {
          background-color: #333;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .recomecar-button {
          width: 30%;
          padding: 15px 20px;
          background-color: ${themeColor || '#333'};
          color: #fff;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          font-size: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.3s;
          position: fixed;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000;
        }
        .recomecar-button:hover {
          background-color: #333;
          opacity: 0.75;
        }
        
        .address-fields label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }

        .address-fields input {
          display: block;
          width: 95%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          font-size: 1rem;
          box-sizing: border-box;
        }

        .address-fields {
          margin-top: 20px;
        }

        .form input, .address-fields input {
          display: block;
          width: 95%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          font-size: 1rem;
        }

        .delivery-estimate {
          font-size: 0.9rem;
          color: #2E7D32;
          margin-left: 10px;
          margin-bottom: 10px;
        }

      `}</style>
    </div>
  );
};

export default Checkout;