import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logopureskintransparent from '../assets/logopureskintransparent.png';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

const SkincareDecisionPage = () => {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [questionnaireId, setQuestionnaireId] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isFinalAnswerSelected, setIsFinalAnswerSelected] = useState(false);

  // Skin tone colors for each option
  const skinToneColors = {
    'Cor 1': '#F5E0C4', // Very Light Skin Tone
    'Cor 2': '#E8C4A2', // Light Peach Skin Tone
    'Cor 3': '#D4A784', // Fair Skin Tone
    'Cor 4': '#B97B56', // Medium Tan Skin Tone
    'Cor 5': '#8C5A35', // Dark Brown Skin Tone
    'Cor 6': '#593B29', // Deep Dark Skin Tone
  };

  // Questions
  const questions = [
    { question: "Qual é o seu gênero?", options: ["Masculino", "Feminino", "Prefiro não informar"], required: true },
    { question: "Qual é o seu tom de pele?", options: ["Cor 1", "Cor 2", "Cor 3", "Cor 4", "Cor 5", "Cor 6"], required: true },
    { question: "Como sua pele geralmente se comporta ao longo do dia?", options: ["Fica brilhante ou oleosa poucas horas após a limpeza", "Permanece equilibrada, sem muito brilho ou ressecamento", "Sente-se ressecada ou com sensação de repuxamento"], required: true },
    { 
      question: "Você apresenta alguma das seguintes condições ou sintomas?", 
      options: ["Espinhas ou cravos", "Manchas escuras ou áreas mais escuras que apareceram com o tempo", "Vermelhidão persistente, sensibilidade ou pequenos vasos sanguíneos visíveis", "Nenhuma das anteriores"], 
      required: true, 
      multiple: true
    },
    { question: "Você anda se preocupando com sinais de envelhecimento, como linhas finas ou rugas?", options: ["Sim", "Não"], required: true },
    { question: "Você pratica esportes ou atividades ao ar livre regularmente?", options: ["Sim", "Não"], required: true },
    { question: "Você usa maquiagem regularmente?", options: ["Sim", "Não"], required: true },
    { question: "Você prefere protetor solar em creme ou em bastão para uso no dia a dia?", options: ["Creme", "Bastão", "Sem preferência"], required: true },
    { question: "Você prefere protetor solar com cor ou sem cor?", options: ["Com cor", "Sem cor"], required: true }
  ];

  // Product IDs
  const products = {
    sunscreenNoColorFair: 'eb511583-d65d-4e46-b28c-d182bef0a592',
    sunscreenNoColorDark: '3ed3100d-adb3-409c-b121-62c4961a88b4',
    sunscreenWithColorCream: {
      'Cor 1': '51d649be-0710-4bdd-b2b8-e9e31fb1acf3',
      'Cor 2': '849aaa1c-0a70-4b43-a23d-3f5b0fd3625a',
      'Cor 3': 'f49ad154-4c34-4301-8475-4110309535e8',
      'Cor 4': '2bf76be4-2ea1-4f8e-be3e-f18f32d4330e',
      'Cor 5': '3ca605f5-2f40-4f38-a4ad-1fe6b2651c3e',
      'Cor 6': '777a86d8-b80d-488b-9311-15d2d2b51b53',
    },
    sunscreenWithColorStick: {
      'Cor 1': '8aee6735-dd19-4e6e-8b32-200385d6bec2',
      'Cor 2': '62b54aff-2d6d-4673-ab4e-64c5d849f13b',
      'Cor 3': '62b54aff-2d6d-4673-ab4e-64c5d849f13b',
      'Cor 4': 'cee801e9-87bb-4b67-b1e8-8246d449fe08',
      'Cor 5': '7e0d8d4f-7a85-4d4b-a265-fa952b913d9a',
      'Cor 6': '7e0d8d4f-7a85-4d4b-a265-fa952b913d9a',
    },
    melasmaNoColor: '2477490c-4ca9-4b48-88bc-76060f030900',
    melasmaWithColor: {
      'Cor 1': 'f6e22221-21df-4efd-aa6b-81dbfde17529',
      'Cor 2': 'f6e22221-21df-4efd-aa6b-81dbfde17529',
      'Cor 3': 'e43e08f8-1b7d-4e88-8338-51f78808fe7b',
      'Cor 4': 'e43e08f8-1b7d-4e88-8338-51f78808fe7b',
      'Cor 5': '11152286-a47d-4f35-bc2f-9deae8ef3906',
      'Cor 6': '11152286-a47d-4f35-bc2f-9deae8ef3906',
    },
    sunscreenWithColorSports: {
      'Cor 1': 'f475e181-04fa-4115-b2bb-a904f0bb6eee',
      'Cor 2': '98ac78dd-0f4d-4ca7-968f-c74a7317ba1a',
      'Cor 3': '292cc5bd-9aaf-4797-814a-da257ddfea8c',
      'Cor 4': '858ac703-6445-4296-b4de-851e0ff16fce',
      'Cor 5': '7e0d8d4f-7a85-4d4b-a265-fa952b913d9a',
      'Cor 6': '7e0d8d4f-7a85-4d4b-a265-fa952b913d9a',
    },
    faceWashOily: '4e13a87f-1ea3-4ba5-ba4f-08f2d81db0ca',
    faceWashDry: 'ac9f56ef-f0a8-4ccb-a06b-4500a3a25966',
    faceWashBalanced: 'eba5ce97-60d9-4897-aa37-f108f49d1063',
    faceHydrator: 'f7e7a17f-9d4d-4624-a6b9-cf782d6eac55',
    serumAntiAcne: 'de0ea598-f0d6-4931-9688-99f25151128b',
    depigmentingCream: '501f982a-f108-489f-a6be-258d2daaebd9',
    rosaceaCream: '56c2a88c-649b-4914-8638-6b5b7189b608',
    makeupRemover: '6bb24e1f-4031-4e14-b935-d2476d77e54d',
    antiWrinkleCream: 'c49e647c-6d55-436e-a62c-527d0ffedd19',
  };

  // Create a new questionnaire only once when the component loads
  useEffect(() => {
    const startQuestionnaire = async () => {
      try {
        const response = await axios.post(`${apiBaseUrl}/questionnaire/start`, { type: 'skincare' });
        setQuestionnaireId(response.data.id);
      } catch (error) {
        console.error("Error starting questionnaire:", error);
      }
    };
    
    if (!questionnaireId) {
      startQuestionnaire();
    }

    // Add event listener for Enter key
    const handleEnter = (e) => {
      if (e.key === 'Enter') {
        if (step < questions.length - 1) {
          handleNext();
        } else if (isFinalAnswerSelected) {
          handleClose();
        }
      }
    };

    window.addEventListener('keydown', handleEnter);
    return () => window.removeEventListener('keydown', handleEnter);
  }, [step, isFinalAnswerSelected, questionnaireId]);

  const buildCart = () => {
    let cartItems = [];
    
    // Mapping answers to specific questions
    const skinTone = answers[1];  // "Qual é o seu tom de pele?"
    const skinBehavior = answers[2];  // "Como sua pele geralmente se comporta ao longo do dia?"
    const skinConditions = answers[3];  // "Você apresenta alguma das seguintes condições ou sintomas?"
    const agingConcern = answers[4];  // "Você se preocupa com sinais de envelhecimento, como linhas finas ou rugas?"
    const sportsActive = answers[5];  // "Você pratica esportes ou atividades ao ar livre regularmente?"
    const makeupUse = answers[6];  // "Você usa maquiagem regularmente?"
    const sunscreenPreference = answers[7];  // "Você prefere protetor solar em creme ou em bastão para uso no dia a dia?"
    const sunscreenColorPreference = answers[8];  // "Você prefere protetor solar com cor ou sem cor?"
    
    const hasMelasma = skinConditions?.includes('Manchas escuras ou áreas mais escuras que apareceram com o tempo');
    const practicesSports = sportsActive === 'Sim';
  
    // Logic for Sunscreen based on Melasma and Preferences
    if (hasMelasma) {
      if(sunscreenColorPreference === 'Sem cor') {
        cartItems.push(products.melasmaNoColor);
      } else {
        // If the user has melasma, choose the melasma sunscreen based on skin tone
        cartItems.push(products.melasmaWithColor[`${skinTone}`]);
      }
    } else if (sunscreenPreference === 'Creme') {
      // If sunscreen preference is cream
      if (sunscreenColorPreference === 'Sem cor') {
        // If they prefer no color, add no color sunscreen
        cartItems.push(
          ['Cor 1', 'Cor 2', 'Cor 3'].includes(skinTone)
            ? products.sunscreenNoColorFair
            : products.sunscreenNoColorDark
        );
      } else {
        // Otherwise, choose the cream sunscreen with color based on skin tone
        cartItems.push(products.sunscreenWithColorCream[`${skinTone}`]);
      }
    } else if (sunscreenPreference === 'Bastão') {
      // Stick preference
      cartItems.push(products.sunscreenWithColorStick[`${skinTone}`]);
    } else {
      // No specific preference, fallback to no color sunscreen
      cartItems.push(
        ['Cor 1', 'Cor 2', 'Cor 3'].includes(skinTone)
          ? products.sunscreenNoColorFair
          : products.sunscreenNoColorDark
      );
    }
  
    // Sports Sunscreen: Always add this product if they practice sports
    if (practicesSports) {
      cartItems.push(products.sunscreenWithColorSports[`${skinTone}`]);
    }
  
    // Skin Type Face Wash Logic
    if (skinBehavior === 'Fica brilhante ou oleosa poucas horas após a limpeza') {
      cartItems.push(products.faceWashOily);
    } else if (skinBehavior === 'Sente-se ressecada ou com sensação de repuxamento') {
      cartItems.push(products.faceWashDry);
      cartItems.push(products.faceHydrator);
    } else {
      cartItems.push(products.faceWashBalanced);
    }
  
    // Optional Products Based on Conditions
    if (skinConditions?.includes('Espinhas ou cravos')) {
      cartItems.push(products.serumAntiAcne);
    }
    if (skinConditions?.includes('Vermelhidão persistente, sensibilidade ou pequenos vasos sanguíneos visíveis')) {
      cartItems.push(products.rosaceaCream);
    }
    if (agingConcern === 'Sim') {
      console.log('here1', agingConcern);
      cartItems.push(products.antiWrinkleCream);
    }
    if (makeupUse === 'Sim') {
      cartItems.push(products.makeupRemover);
    }
  
    // Ensure no null or undefined products are added to the cart
    return cartItems.filter(item => item);
  };
  
  const saveAnswers = async (updatedAnswers) => {
    if (!questionnaireId) return;

    try {
      await axios.put(`${apiBaseUrl}/questionnaire/${questionnaireId}`, { answers: updatedAnswers });
    } catch (error) {
      console.error("Error saving answers:", error);
    }
  };

  const handleAnswer = (questionIndex, answer) => {
    let updatedAnswers = { ...answers };

    if (questions[questionIndex].multiple) {
      updatedAnswers[questionIndex] = updatedAnswers[questionIndex] || [];
      if (updatedAnswers[questionIndex].includes(answer)) {
        updatedAnswers[questionIndex] = updatedAnswers[questionIndex].filter(opt => opt !== answer);
      } else {
        updatedAnswers[questionIndex].push(answer);
      }
    } else {
      updatedAnswers[questionIndex] = answer;
    }

    setAnswers(updatedAnswers);
    setError(false);

    // Save answers to the API after each response
    saveAnswers(updatedAnswers);

    // Trigger cart building and log the cart after each answer
    const currentCart = buildCart();

    // Check if final question is answered to enable the final button
    if (step === questions.length - 1 && updatedAnswers[questionIndex]) {
      setIsFinalAnswerSelected(true);
    }
  };

  const handleNext = () => {
    // Check if the current step's answer is valid (required)
    if (questions[step].required && (!answers[step] || answers[step].length === 0)) {
      setError(true);
      return;
    }

    setIsFinalAnswerSelected(false); // Reset for the next question

    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      setIsFinalAnswerSelected(true);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleClose = () => {
    if (isFinalAnswerSelected) {
      const finalCart = buildCart();
      console.log('finalCart', finalCart);

      if (questionnaireId) {
        completeQuestionnaire();
      }

      navigate(`/selected_shop/pureskin?selecteds=${finalCart.join(',')}`);
    }
  };

  const completeQuestionnaire = async () => {
    try {
      await axios.put(`${apiBaseUrl}/questionnaire/${questionnaireId}/complete`);
    } catch (error) {
      console.error("Error completing questionnaire:", error);
    }
  };

  return (
    <div className="container">
      <img src={logopureskintransparent} alt={'pureskin'} className="logo-image" />
      <div className="progress-bar">
        {questions.map((_, index) => (
          <div
            key={index}
            className={`progress-segment ${index <= step ? 'active' : ''}`}
          ></div>
        ))}
      </div>

      <div className="card">
        <h2 className="question-header">{questions[step].question}</h2>
        <div className="options">
          {questions[step].options.map((option, index) => (
            <div key={index} className="option">
              <input
                type={questions[step].multiple ? 'checkbox' : 'radio'}
                id={`q${step}-o${index}`}
                name={`question-${step}`}
                value={option}
                onChange={() => handleAnswer(step, option)}
                checked={questions[step].multiple ? answers[step]?.includes(option) : answers[step] === option}
                className={questions[step].multiple ? 'checkbox-input' : 'radio-input'}
              />
              <label htmlFor={`q${step}-o${index}`} className="radio-label">
                {questions[step].question === "Qual é o seu tom de pele?" && <span className="color-box" style={{ backgroundColor: skinToneColors[option] }}></span>}
                {questions[step].question !== "Qual é o seu tom de pele?" && option}
              </label>
            </div>
          ))}
        </div>
        {error && <p style={{ color: 'red' }}>Esta pergunta é obrigatória</p>}
        <div className="buttons">
          <button disabled={step === 0} className={`button back-button ${step === 0 ? 'disabled' : ''}`} onClick={handleBack}>Voltar</button>
          {step < questions.length - 1 ? (
            <button className="button next-button" onClick={handleNext}>Próxima</button>
          ) : (
            <button 
              className={`button finish-button ${!isFinalAnswerSelected ? 'disabled' : ''}`}
              onClick={handleClose} 
              disabled={!isFinalAnswerSelected}
            >
              Ver meus produtos
            </button>
          )}
        </div>
      </div>

      <style>{`
        .container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          height: 100vh;
          font-family: 'Poppins', sans-serif;
          margin-bottom: 300px;
        }

        .progress-bar {
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;
          width: 80%;
          max-width: 600px;
        }

        .progress-segment {
          flex: 1;
          height: 10px;
          background-color: #e0e0e0;
          margin-right: 5px;
          border-radius: 5px;
        }

        .progress-segment:last-child {
          margin-right: 0;
        }

        .progress-segment.active {
          background-color: #ec407a;
        }

        .card {
          max-width: 600px;
          width: 80%;
          padding: 20px;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          text-align: center;
        }

        .question-header {
          text-align: left;
          padding: 0 20px;
          font-size: 1.5rem;
          margin-bottom: 40px;
          color: #333;
        }

        .options {
          text-align: left;
          padding: 0 30px;
          margin-bottom: 40px;
        }

        .option {
          padding: 0 20px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }

        .radio-input {
          margin-right: 10px;
        }

        .color-box {
          width: 100%;
          height: 30px;
          margin-right: 10px;
          border-radius: 3px;
          border: 1px solid #ccc;
        }

        .radio-label {
          display: flex;
          align-items: center;
          font-size: 1rem;
          width: 100%;
        }

        .radio-input:checked + .radio-label {
          color: #ec407a;
        }

        .radio-input[type="radio"]:checked::before {
          box-shadow: inset 0 0 0 5px white;
          background-color: #ec407a;
          border-color: #ec407a;
        }

        .radio-input[type="radio"] {
          appearance: none;
          width: 16px;
          height: 16px;
          border: 2px solid #e0e0e0;
          border-radius: 50%;
          outline: none;
          transition: background-color 0.2s, border-color 0.2s;
          margin-right: 10px;
        }

        .radio-input[type="radio"]:checked {
          border-color: #ec407a;
          background-color: #ec407a;
        }

        /* Checkbox styling for multiple choice questions */
        .checkbox-input[type="checkbox"] {
          appearance: none;
          width: 24px; /* Slightly larger for a modern feel */
          height: 24px;
          border: 2px solid #e0e0e0;
          border-radius: 6px; /* Increased rounding for a smoother look */
          cursor: pointer;
          position: relative;
          margin-right: 12px;
          transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transitions */
          display: inline-block;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
        }

        .checkbox-input[type="checkbox"]:hover {
          background-color: #e0e0e070; /* Change border on hover */
        }

        .checkbox-input[type="checkbox"]:checked {
          background-color: #ec407a;
          border-color: #ec407a;
        }

        .checkbox-input[type="checkbox"]:checked::before {
          content: '\\2713'; /* Checkmark character */
          display: block;
          text-align: center;
          color: white;
        }

        .buttons {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
        }

        .button {
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          color: #fff;
          font-size: 1rem;
          cursor: pointer;
        }

        .button.disabled {
          background-color: #d3d3d3;
          cursor: not-allowed;
        }

        .back-button {
          background-color: #e57373;
        }

        .next-button {
          background-color: #f06292;
        }

        .finish-button {
          background-color: #ec407a;
        }

        .success-popup {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 20px;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          text-align: center;
        }

        .close-popup {
          background-color: #ec407a;
        }

        .close-popup:hover {
          background-color: #d81b60;
        }

        .required-asterisk {
          color: red;
        }

        .logo-image {
            margin-top: 40px;
            width: 25%;
            height: auto;
            object-fit: cover;
            margin-bottom: 40px;
        }

        @media (max-width: 768px) {
            .logo-image {
                width: 65%;
            }
        }
      `}</style>
    </div>
  );
};

export default SkincareDecisionPage;
