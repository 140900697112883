import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import Chat from '../components/Chat';
import logopureskin from '../assets/logopureskin.jpeg';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

// API functions
const getCatalogBySlug = async (slug) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/catalogs/slug/${slug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching catalog:', error);
    return null;
  }
};

const getProductsByCatalogSlug = async (catalogSlug) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/products/slug/${catalogSlug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};

const ChatSelectedShop = (props) => {
  const { catalogSlug: paramSlug } = useParams();
  const catalogSlug = props.catalogSlug || paramSlug;
  const location = useLocation();
  const navigate = useNavigate();
  
  const [catalog, setCatalog] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    const fetchCatalogData = async () => {
      const searchParams = new URLSearchParams(location.search);
      setName(searchParams.get('name'));
      setPhone(searchParams.get('phone'));

      const catalogData = await getCatalogBySlug(catalogSlug);
      setCatalog(catalogData);

      const companyId = catalogData.company_id;
      setCompanyId(companyId);

      const productsData = await getProductsByCatalogSlug(catalogSlug);
      setProducts(productsData);      

      const productIds = searchParams.get('products');
      const filteredProductIds = productIds ? productIds.split(',') : [];

      const selectedIds = searchParams.get('selecteds');
      const filteredSelectedIds = selectedIds ? selectedIds.split(',') : [];

      setSelecteds(filteredSelectedIds);

      if (filteredProductIds.length > 0) {
        const initialCart = filteredProductIds.map(id => {
          const product = productsData.find(product => product.id === id);
          const existingProduct = cart.find(item => item.id === id);
          return existingProduct
            ? { ...product, quantity: existingProduct.quantity }
            : { ...product, quantity: 1 };
        });
        setCart(initialCart);
      }

      const initialImageIndex = {};
      productsData.forEach(product => {
        initialImageIndex[product.id] = 0;
      });
      setCurrentImageIndex(initialImageIndex);
    };

    fetchCatalogData();
  }, [catalogSlug, location.search]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setSelectedProduct(null);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const updateUrlWithSelectedProducts = (updatedCart) => {
    const productIds = updatedCart.map((product) => product.id).join(',');
    const searchParams = new URLSearchParams(location.search);
    if (productIds) {
      searchParams.set('products', productIds);
    } else {
      searchParams.delete('products');
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const handleNextImage = (productId) => {
    setCurrentImageIndex((prev) => {
      const newIndex = (prev[productId] + 1) % products.find(p => p.id === productId).image_urls.length;
      return { ...prev, [productId]: newIndex };
    });
  };

  const handlePrevImage = (productId) => {
    setCurrentImageIndex((prev) => {
      const product = products.find(p => p.id === productId);
      const newIndex = (prev[productId] - 1 + product.image_urls.length) % product.image_urls.length;
      return { ...prev, [productId]: newIndex };
    });
  };

  const handleModalNextImage = () => {
    if (selectedProduct) {
      setModalImageIndex((prevIndex) => (prevIndex + 1) % selectedProduct.image_urls.length);
    }
  };

  const handleModalPrevImage = () => {
    if (selectedProduct) {
      setModalImageIndex((prevIndex) => (prevIndex - 1 + selectedProduct.image_urls.length) % selectedProduct.image_urls.length);
    }
  };

  const handleAddToCart = () => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find(item => item.id === selectedProduct.id);
      const updatedCart = existingProduct
        ? prevCart.map(item =>
            item.id === selectedProduct.id ? { ...item, quantity } : item
          )
        : [...prevCart, { ...selectedProduct, quantity }];

      updateUrlWithSelectedProducts(updatedCart);
      return updatedCart;
    });

    setSelectedProduct(null);
    setQuantity(1);
    setModalImageIndex(0);
  };

  const handleCheckout = () => {
    const productParams = cart.map(item => `${item.id}:${item.quantity}`).join(',');
    const searchParams = new URLSearchParams();
  
    searchParams.set('origin', 'chat_selected_shop');
    searchParams.set('products', productParams);
    searchParams.set('companyId', companyId);
    searchParams.set('catalogSlug', catalog.slug);
    searchParams.set('fromSelected', true);
  
    navigate(`/checkout?${searchParams.toString()}`);
  };

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDeleteCart = () => {
    setCart([]); // Clear the cart
    updateUrlWithSelectedProducts([]); // Clear URL parameters
    setShowDeleteModal(false); // Close the modal
  };

  const filteredProducts = products
    .filter(product => 
      selecteds.length === 0 || selecteds.includes(product.id) // Filter by selecteds
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(product => {
      const cartProduct = cart.find(item => item.id === product.id);
      return {
        ...product,
        quantity: cartProduct ? cartProduct.quantity : 0,
      };
    });

  return (
    <div className="container">
      {catalog && (
        <div className="catalog-header">
          {catalogSlug != 'pureskin' && <img src={catalog.image_url} alt={catalog.name} className="catalog-image" />}
          {catalogSlug == 'pureskin' && <img src={logopureskin} alt={catalog.name} className="catalog-image" />}
          <div className="catalog-titles">
            <h1 className="catalog-name">{catalog.name}</h1>
            <h2 className="catalog-description">Cada produto foi cuidadosamente recomendado com base nas respostas do questionário, atendendo às necessidades específicas identificadas.</h2>
          </div>
        </div>
      )}

      <hr className="horizontal-line" />
      
      <div className="products-container">
        {filteredProducts.map((product) => (
          <div key={product.id} className="product-card" onClick={() => { setSelectedProduct(product); setModalImageIndex(0); }}>
            <div className="image-scroll-container">
              <div className="image-wrapper" style={{ transform: `translateX(-${currentImageIndex[product.id] * 100}%)`, width: '100%' }}>
                {product.image_urls.map((url, index) => (
                  <div key={index} className="image-slide">
                    <img 
                      src={url} 
                      alt={product.name} 
                      className="product-image" 
                    />
                  </div>
                ))}
              </div>
              {currentImageIndex[product.id] > 0 && (
                <button className="arrow-button left" onClick={(e) => { e.stopPropagation(); handlePrevImage(product.id); }}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="3">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                  </svg>
                </button>
              )}
              {currentImageIndex[product.id] < product.image_urls.length - 1 && (
                <button className="arrow-button right" onClick={(e) => { e.stopPropagation(); handleNextImage(product.id); }}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="3">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              )}
            </div>
            <div className="product-info">
              <h2>{product.name}</h2>
              <ReactMarkdown className="product-description">
                {product.description}
              </ReactMarkdown>
              <p className="product-price">R$ {product.price}</p>
            </div>
            {product.quantity > 0 && (
              <div className="quantity-indicator">
                {product.quantity}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="action-buttons">
        <button 
          className="checkout-button" 
          onClick={handleCheckout}
          disabled={cart.length === 0}
        >
          Checkout
        </button>
        <button 
          className="trash-button" 
          onClick={handleOpenDeleteModal}
          disabled={cart.length === 0}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseDeleteModal}>×</button>
            <h2>Remover Itens</h2>
            <p>Deseja remover {cart.length} itens do seu carrinho?</p>
            <div className="modal-actions">
              <button className="cancel-delete" onClick={handleCloseDeleteModal}>Não</button>
              <button className="confirm-delete" onClick={handleConfirmDeleteCart}>Sim</button>
            </div>
          </div>
        </div>
      )}

      {selectedProduct && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={() => setSelectedProduct(null)}>×</button>
            <h2>{selectedProduct.name}</h2>
            
            <div className="modal-image-container">
              <div className="modal-image-wrapper" style={{ transform: `translateX(-${modalImageIndex * 100}%)`, width: '100%' }}>
                {selectedProduct.image_urls.map((url, index) => (
                  <div key={index} className="image-slide">
                    <img 
                      src={url} 
                      alt={selectedProduct.name} 
                      className="modal-image" 
                    />
                  </div>
                ))}
              </div>
              {modalImageIndex > 0 && (
                <button className="modal-arrow left" onClick={handleModalPrevImage}>
                  ‹
                </button>
              )}
              {modalImageIndex < selectedProduct.image_urls.length - 1 && (
                <button className="modal-arrow right" onClick={handleModalNextImage}>
                  ›
                </button>
              )}
            </div>
            
            <ReactMarkdown className="modal-description">
              {selectedProduct.description}
            </ReactMarkdown>            
            <div className="quantity-selector">
              <button onClick={() => setQuantity(Math.max(quantity - 1, 1))}>-</button>
              <span>{quantity}</span>
              <button onClick={() => setQuantity(quantity + 1)}>+</button>
            </div>
            
            <button className="add-to-cart" onClick={handleAddToCart}>Adicionar ao carrinho</button>
          </div>
        </div>
      )}

    <Chat color={catalog?.theme_color ? catalog.theme_color : '#333'} catalogSlug={catalogSlug} />

    <style>{`
      .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        font-family: 'Poppins', sans-serif;
        color: #333;
      }
      .catalog-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        overflow-x: auto;
      }
      .catalog-image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 20px;
        border: 3px solid ${catalog?.theme_color ? catalog.theme_color : '#333'};
      }
      .catalog-name {
        font-size: 2rem;
        font-weight: bold;
        color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        margin: 0;
      }
      .catalog-description {
        font-size: 1.1rem;
        color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        opacity: 0.8;
      }
      .horizontal-line {
        margin: 20px 0;
        border-bottom: 2px solid ${catalog?.theme_color ? catalog.theme_color : '#333'};
      }

      .products-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Ensure exactly 3 cards per row */
        gap: 20px;
        justify-content: center;
        max-width: 1200px; /* Center the entire block */
        margin: 0 auto;
        padding: 0 10px;
      }

      .product-card {
        background-color: #fff;
        border-radius: 15px;
        border: 1px solid #ddd;
        padding: 10px;
        box-shadow: 0 0 10px ${catalog?.theme_color ? catalog.theme_color : '#333'};
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        justify-content: flex-start;
        position: relative;
        transition: box-shadow 0.3s ease;
      }

      .product-card:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        border-color: ${catalog?.theme_color ? catalog.theme_color : '#f06292'};
      }

      @media (max-width: 1200px) {
        .products-container {
          grid-template-columns: repeat(2, 1fr); /* Switch to 2 cards per line on medium screens */
        }
      }

      @media (max-width: 768px) {
        .products-container {
          grid-template-columns: repeat(1, 1fr); /* Switch to 1 card per line on small screens */
        }
      }

      @media (max-width: 480px) {
        .products-container {
          grid-template-columns: 1fr; /* Full width for mobile screens */
        }
      }

      .image-scroll-container {
        display: flex;
        justify-content: center;
        overflow: hidden;
        height: auto;
        width: 100%;
        max-height: 250px;
      }

      .image-wrapper {
        display: flex;
        transition: transform 0.5s ease;
      }

      .image-slide {
        flex-shrink: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .product-image {
        max-width: 100%;
        max-height: 250px;
        object-fit: contain;
        display: block;
        margin: 0 auto;
      }

      .product-info {
        text-align: center;
        margin-top: 10px;
      }

      .product-info h2 {
        margin: 0 0 5px;
        font-size: 1.2rem;
      }

      .product-description {
        display: -webkit-box;
        -webkit-line-clamp: 4; /* Limits to 5 lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
      }
      .product-description::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%; /* Adjust this to control the height of the fade */
        width: 100%;
        background: linear-gradient(transparent, white);
      }

      .product-price {
        margin: 10px 0;
        color: #333;
        font-weight: bold;
        font-size: 1.4rem;
      }

      .quantity-indicator {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        color: white;
        border-radius: 50%;
        padding: 5px;
        font-size: 0.9rem;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .action-buttons {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        max-width: 500px;
        display: flex;
        justify-content: center;
        gap: 15px;
        z-index: 1000;
      }

      .checkout-button {
        width: 70%;
        padding: 15px 20px;
        background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        color: #fff;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-size: 1.2rem;
        transition: background-color 0.3s;
      }

      .checkout-button:hover {
        background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        opacity: 0.8;
      }

      .checkout-button:disabled {
        background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        cursor: not-allowed;
        opacity: 0.4;
      }

      .trash-button {
        width: 50px;
        height: 50px;
        background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        color: #fff;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .trash-button:disabled {
        background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        cursor: not-allowed;
        opacity: 0.4;
      }

      .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
      }

      .modal-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        max-width: 800px;  /* You can adjust this */
        width: 90%;
        max-height: 95%;  /* Max height of the modal */
        overflow-y: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .close-modal {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 1.5rem;
        background: none;
        border: none;
        cursor: pointer;
      }

      .modal-image-container {
        width: 100%;
        flex-grow: 1; /* Make sure images take most of the space */
        overflow: hidden;
      }

      .modal-image-wrapper {
        display: flex;
        transition: transform 0.5s ease;
      }

      .modal-image {
        max-width: 100%;
        max-height: 100%; /* Ensure image fills available space */
        object-fit: contain;
        display: block;
        margin: 0 auto;
      }

      .modal-arrow {
        position: absolute;
        background-color: transparent;
        border: none;
        padding: 10px;
        cursor: pointer;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
      }

      .modal-arrow.left {
        left: 10px;
      }

      .modal-arrow.right {
        right: 10px;
      }

      .modal-price, .modal-total-price {
        font-size: 1rem;
        font-weight: bold;
        margin: 0px 0;
        text-align: center;
      }
      .quantity-selector {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        width: 100%;
        max-width: 200px;
      }

      .quantity-selector button {
        padding: 5px 10px;
        font-size: 1.2rem;
      }

      .quantity-selector span {
        font-size: 1.2rem;
      }

      .add-to-cart {
          background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          color: #fff;
          padding: 10px;
          border: none;
          cursor: pointer;
          margin-top: 20px;
          width: 100%;
          max-width: 200px;
          border-radius: 10px; /* Added for rounded corners */
      }
      .add-to-cart:hover {
        background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        opacity: 0.8;
      }

      .modal-actions {
        display: flex;
        justify-content: center;
        gap: 15px;
        margin-top: 20px;
      }

      .cancel-delete {
        background-color: #e0e0e0;
        color: black;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      .confirm-delete {
        background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      .floating-chat-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        color: white;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        cursor: pointer;
        z-index: 1001;
      }

      .chat-container {
        position: fixed;
        bottom: 80px;
        right: 20px;
        width: 100%;
        max-width: 400px;
        height: 500px;
        background-color: white;
        border: 1px solid #ccc;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1000;
      }

      @media (max-width: 768px) {
        .chat-container {
          width: 95%;
          height: 40%;
        }
      }
    `}</style>
  </div>
  );
};

export default ChatSelectedShop;
