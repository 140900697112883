import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Helper function to fetch address by ZIP code
async function getAddressByCep(cep) {
  try {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return response.data.logradouro;
  } catch (error) {
    console.error('Erro ao buscar o endereço:', error);
    return null;
  }
}

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

// API Function for signing up
const signUp = async (company) => {
  return await axios.post(`${apiBaseUrl}/signup`, company);
};

const SignUp = () => {
  const navigate = useNavigate();
  const [company, setCompany] = useState({
    phone: '',
    email: '',
    password: '',
    document: '',
    name: '',
    zip_code: '',
    full_address: '',
  });
  const [popup, setPopup] = useState({ message: '', isError: false });

  const handleSignUp = async () => {
    try {
      const response = await signUp(company);
      const catalogSlug = response.data.catalogSlug;
  
      setPopup({ message: 'Cadastro realizado com sucesso', isError: false });
      setTimeout(() => {
        setPopup({ message: '', isError: false });
        navigate(`/edit_catalog/${catalogSlug}`);
      }, 1000);
    } catch (error) {
      setPopup({ message: 'Erro ao realizar cadastro', isError: true });
      console.error('Erro ao realizar cadastro', error);
    }
  };

  const handleZipCodeChange = async (e) => {
    const zip = e.target.value;
    setCompany({ ...company, zip_code: zip });

    if (zip.length === 8) {
      const addressInfo = await getAddressByCep(zip);
      setCompany({ ...company, full_address: addressInfo, zip_code: zip });
    }
  };

  const closePopup = () => {
    setPopup({ message: '', isError: false });
  };

  return (
    <div className="container">
      {popup.message && (
        <div className={`popup ${popup.isError ? 'error' : 'success'}`}>
          {popup.message}
          <button onClick={closePopup}>Fechar</button>
        </div>
      )}
      <div className="form-container">
        <h2>Cadastre-se</h2>
        <input
          type="phone"
          placeholder="Telefone"
          value={company.phone}
          onChange={(e) => setCompany({ ...company, phone: e.target.value })}
        />
        <input
          type="email"
          placeholder="Email"
          value={company.email}
          onChange={(e) => setCompany({ ...company, email: e.target.value })}
        />
        <input
          type="password"
          placeholder="Senha"
          value={company.password}
          onChange={(e) => setCompany({ ...company, password: e.target.value })}
        />
        <input
          type="text"
          placeholder="CPF/CNPJ"
          value={company.document}
          onChange={(e) => setCompany({ ...company, document: e.target.value })}
        />
        <input
          type="text"
          placeholder="Nome da Empresa"
          value={company.name}
          onChange={(e) => setCompany({ ...company, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="CEP"
          value={company.zip_code}
          onChange={handleZipCodeChange}
        />
        <input
          type="text"
          placeholder="Endereço Completo"
          value={company.full_address}
          onChange={(e) => setCompany({ ...company, full_address: e.target.value })}
        />
        <button onClick={handleSignUp}>Cadastrar</button>
        <p>
          Já tem uma conta?{' '}
          <span
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => navigate('/signin')}
          >
            Login
          </span>
        </p>
      </div>
      <style>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
          background-color: #f0f2f5;
          padding: 20px;
        }
        .form-container {
          background: white;
          padding: 30px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 400px;
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        .form-container input {
          width: 95%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
        }
        .form-container button {
          padding: 10px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .form-container button:hover {
          background-color: #0056b3;
        }
        .popup {
          position: fixed;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          padding: 20px;
          background-color: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          z-index: 1000;
        }
        .popup.success {
          border: 1px solid green;
        }
        .popup.error {
          border: 1px solid red;
        }
        .popup button {
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
};

export default SignUp;
