import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import logopureskin from '../assets/logopureskin.jpeg';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

// API functions
const getCatalogBySlug = async (slug) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/catalogs/slug/${slug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching catalog:', error);
    return null;
  }
};

const getProductsByCatalogSlug = async (catalogSlug) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/products/slug/${catalogSlug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};

const Shop = (props) => {
  const { catalogSlug: paramSlug } = useParams();
  const catalogSlug = props.catalogSlug || paramSlug;
  
  const location = useLocation();
  const navigate = useNavigate();
  
  const [catalog, setCatalog] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [sortOption, setSortOption] = useState('alphabetical');

  useEffect(() => {
    const fetchCatalogData = async () => {
      const searchParams = new URLSearchParams(location.search);
      setName(searchParams.get('name'));
      setPhone(searchParams.get('phone'));

      const catalogData = await getCatalogBySlug(catalogSlug);
      setCatalog(catalogData);

      const companyId = catalogData.company_id;
      setCompanyId(companyId);

      const productsData = await getProductsByCatalogSlug(catalogSlug);
      setProducts(productsData);

      const productIds = searchParams.get('products');
      const filteredIds = productIds ? productIds.split(',') : [];

      if (filteredIds.length > 0) {
        const initialCart = filteredIds.map(id => {
          const product = productsData.find(product => product.id === id);
          const existingProduct = cart.find(item => item.id === id);
          return existingProduct
            ? { ...product, quantity: existingProduct.quantity }
            : { ...product, quantity: 1 };
        });
        setCart(initialCart);
      }

      let uniqueCategories = [...new Set(productsData.flatMap(product => product.categories))];

      if (uniqueCategories.includes('Serviços')) {
        uniqueCategories = ['Serviços', ...uniqueCategories.filter(cat => cat !== 'Serviços')];
      }

      setCategories(['All', ...uniqueCategories]);

      const initialImageIndex = {};
      productsData.forEach(product => {
        initialImageIndex[product.id] = 0;
      });
      setCurrentImageIndex(initialImageIndex);
    };

    fetchCatalogData();
  }, [catalogSlug, location.search]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setSelectedProduct(null);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const updateUrlWithSelectedProducts = (updatedCart) => {
    const productIds = updatedCart.map((product) => product.id).join(',');
    const searchParams = new URLSearchParams(location.search);
    if (productIds) {
      searchParams.set('products', productIds);
    } else {
      searchParams.delete('products');
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const handleNextImage = (productId) => {
    setCurrentImageIndex((prev) => {
      const product = products.find(p => p.id === productId);
      const newIndex = prev[productId] + 1;
      return { ...prev, [productId]: newIndex < product.image_urls.length ? newIndex : prev[productId] };
    });
  };

  const handlePrevImage = (productId) => {
    setCurrentImageIndex((prev) => {
      const product = products.find(p => p.id === productId);
      const newIndex = prev[productId] - 1;
      return { ...prev, [productId]: newIndex >= 0 ? newIndex : prev[productId] };
    });
  };

  const handleModalNextImage = () => {
    if (selectedProduct) {
      setModalImageIndex((prevIndex) => (prevIndex + 1) % selectedProduct.image_urls.length);
    }
  };

  const handleModalPrevImage = () => {
    if (selectedProduct) {
      setModalImageIndex((prevIndex) => (prevIndex - 1 + selectedProduct.image_urls.length) % selectedProduct.image_urls.length);
    }
  };

  const handleAddToCart = () => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find(item => item.id === selectedProduct.id);
      const updatedCart = existingProduct
        ? prevCart.map(item =>
            item.id === selectedProduct.id ? { ...item, quantity } : item
          )
        : [...prevCart, { ...selectedProduct, quantity }];

      updateUrlWithSelectedProducts(updatedCart);
      return updatedCart;
    });

    setSelectedProduct(null);
    setQuantity(1);
    setModalImageIndex(0);
  };

  const handleCheckout = () => {
    const productParams = cart.map(item => `${item.id}:${item.quantity}`).join(',');
    const searchParams = new URLSearchParams();
  
    searchParams.set('origin', 'shop');
    searchParams.set('products', productParams);
    searchParams.set('companyId', companyId);
    searchParams.set('catalogSlug', catalog.slug);
    searchParams.set('fromSelected', true);
    console.log('themeColor',catalog.theme_color);
    searchParams.set('themeColor', catalog.theme_color);
  
    navigate(`/checkout?${searchParams.toString()}`);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortedProducts = [...products].sort((a, b) => {
    if (sortOption === 'alphabetical') {
      return a.name.localeCompare(b.name);
    } else if (sortOption === 'price') {
      return a.price - b.price;
    }
    return 0;
  });

  const filteredProducts = sortedProducts
    .filter(product =>
      selectedCategory === 'All' || product.categories.includes(selectedCategory)
    )
    .map(product => {
      const cartProduct = cart.find(item => item.id === product.id);
      return {
        ...product,
        quantity: cartProduct ? cartProduct.quantity : 0,
        shortDescription: product.description.split('\n')[0],
      };
    });

  return (
    <div className="shop-container">
      {catalog && (
        <div className="catalog-header">
          <div className="catalog-top">
            {catalogSlug !== 'pureskin' && <img src={catalog.image_url} alt={catalog.name} className="catalog-image" />}
            {catalogSlug === 'pureskin' && <img src={logopureskin} alt={catalog.name} className="catalog-image" />}
            <div className="catalog-titles">
              <h1 className="catalog-name">{catalog.name}</h1>
              <h2 className="catalog-description">{catalog.description}</h2>
            </div>
          </div>
          <div className="sort-container">
            <label htmlFor="sort" className="sort-label">Ordenação:</label>
            <select id="sort" value={sortOption} onChange={handleSortChange} className="sort-select" style={{ fontSize: '1.2rem' }}>
              <option value="alphabetical">Alfabética</option>
              <option value="price">Preço</option>
            </select>
          </div>
        </div>
      )}

      <nav className="categories-navbar">
        {categories.map((category, index) => (
          <button
            key={index}
            className={`category-button ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category)}
            style={{
              backgroundColor: selectedCategory === category ? catalog?.theme_color || '#007bff' : 'transparent',
              color: selectedCategory === category ? '#fff' : '#333',
              borderRadius: selectedCategory === category ? '20px' : '0',
              fontSize: '1.2rem',
            }}
          >
            {category}
          </button>
        ))}
      </nav>

      <div className="products-container">
        {filteredProducts.map((product) => (
          <div key={product.id} className="product-card" onClick={() => { setSelectedProduct(product); setModalImageIndex(0); }}>
            <div className="image-scroll-container">
              <div className="image-wrapper" style={{ transform: `translateX(-${currentImageIndex[product.id] * 100}%)` }}>
                {product.image_urls.map((url, index) => (
                  <div key={index} className="image-slide">
                    <img
                      src={url}
                      alt={product.name}
                      className="product-image"
                    />
                  </div>
                ))}
              </div>
              <div
                className="arrow-button left"
                onClick={(e) => { e.stopPropagation(); handlePrevImage(product.id); }}
                style={{ opacity: currentImageIndex[product.id] === 0 ? 0.5 : 1 }}
              > {'<'} </div>
              <div
                className="arrow-button right"
                onClick={(e) => { e.stopPropagation(); handleNextImage(product.id); }}
                style={{ opacity: currentImageIndex[product.id] === product.image_urls.length - 1 ? 0.5 : 1 }}
              > {'>'} </div>
            </div>
            <div className="product-info">
              <h2 className="product-title" style={{ textAlign: 'left', fontSize: '1.8rem' }}>{product.name}</h2>
              <p className="product-description" style={{ textAlign: 'left', fontSize: '1.4rem' }}>{product.shortDescription}</p>
              <p className="product-price" style={{ textAlign: 'left', fontSize: '1.6rem', color: catalog?.theme_color || '#28a745' }}>R$ {product.price.toFixed(2)}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="action-buttons">
        <button className="checkout-button" onClick={handleCheckout} disabled={cart.length === 0} style={{ fontSize: '1.3rem' }}>Checkout ({cart.length}) <FontAwesomeIcon icon={faShoppingCart} /></button>
      </div>

      {selectedProduct && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={() => setSelectedProduct(null)}>×</button>
            <h2 className="modal-title" style={{ fontSize: '2.5rem', marginBottom: '15px' }}>{selectedProduct.name}</h2>
            <div className="modal-image-container">
              <div className="modal-image-wrapper" style={{ transform: `translateX(-${modalImageIndex * 100}%)` }}>
                {selectedProduct.image_urls.map((url, index) => (
                  <div key={index} className="image-slide">
                    <img src={url} alt={selectedProduct.name} className="modal-image" />
                  </div>
                ))}
              </div>
              <div
                className="arrow-button-modal left"
                onClick={handleModalPrevImage}
                style={{ opacity: modalImageIndex === 0 ? 0.5 : 1 }}
              > {'<'} </div>
              <div
                className="arrow-button-modal right"
                onClick={handleModalNextImage}
                style={{ opacity: modalImageIndex === selectedProduct.image_urls.length - 1 ? 0.5 : 1 }}
              > {'>'} </div>
            </div>
            <ReactMarkdown className="markdown-content" style={{ fontSize: '1.6rem', lineHeight: '1.7', margin: '20px 0' }}>
              {selectedProduct.description}
            </ReactMarkdown>
            <p className="modal-price" style={{ fontSize: '1.8rem', fontWeight: 'bold', color: catalog?.theme_color || '#28a745' }}>R$ {selectedProduct.price.toFixed(2)}</p>
            <div className="quantity-selector">
              <button onClick={() => setQuantity(Math.max(quantity - 1, 1))} className="quantity-button">-</button>
              <span className="quantity-value">{quantity}</span>
              <button onClick={() => setQuantity(quantity + 1)} className="quantity-button">+</button>
            </div>
            <div className="add-to-cart-container">
              <button className="add-to-cart" onClick={handleAddToCart}>Adicionar ao carrinho</button>
            </div>
          </div>
        </div>
      )}

<style>{`
  .markdown-content {
    line-height: 1.7;
    font-size: 1.6rem;
    margin-top: 20px;
    white-space: pre-line;
  }
  .shop-container {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;
    width: 100%;
    margin: 0 auto;
  }

  .catalog-top {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: left;
  }
  .catalog-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
  }
  .catalog-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .catalog-name {
    font-size: 2.5rem;
    font-weight: bold;
    color: ${catalog?.theme_color || '#333'};
  }
  .catalog-description {
    font-size: 1.2rem;
    color: #666;
  }

  .categories-navbar {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
    overflow-x: auto;
    padding: 10px 20px;
    background-color: transparent;
    box-shadow: none;
    scroll-behavior: smooth;
    white-space: nowrap;
  }
  .categories-navbar::-webkit-scrollbar {
    height: 8px;
  }
  .categories-navbar::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }
  .categories-navbar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  @media (max-width: 1536px) {
    .categories-navbar {
      justify-content: flex-start;
    }
  }

  .category-button {
    padding: 10px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    transition: 0.3s;
    white-space: nowrap;
    font-weight: normal;
    font-size: 1.5rem;
  }
  .category-button:hover {
    font-weight: bold;
  }
  .category-button.active {
    background-color: ${catalog?.theme_color || '#007bff'};
    color: #fff;
    border-radius: 20px;
    font-weight: bold;
  }

  .sort-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 80px;
    font-size: 1.2rem;
  }

  .sort-label {
    font-weight: bold;
    margin: 0;
    line-height: 1;
  }

  .sort-select {
    height: 35px;
    padding: 5px 10px;
    font-size: 1.2rem;
    line-height: 1;
    border-radius: 5px;
  }

  .products-container {
    display: grid;
    gap: 20px;
    margin-bottom: 20px;
    padding: 0 200px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1536px) {
    .products-container {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 100px;
    }
  }
  @media (max-width: 956px) {
    .products-container {
      grid-template-columns: 1fr;
      padding: 0 20px;
    }
  }

  .product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.3s, transform 0.3s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    min-height: 500px;
  }
  .product-card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
  }

  .image-scroll-container {
    position: relative;
    overflow: hidden;
    height: 350px;
  }

  .image-wrapper {
    display: flex;
    height: 100%;
    transition: transform 0.5s ease;
  }

  .image-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .arrow-button {
    position: absolute;
    top: 50%;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1;
    padding: 10px;
    background: transparent;
    border: none;
    opacity: 0;
    transition: opacity 0.3s;
    display: none;
  }

  .product-card:hover .arrow-button {
    opacity: 1;
    display: block;
  }

  .arrow-button.left {
    left: 10px;
  }
  .arrow-button.right {
    right: 10px;
  }

  .arrow-button-modal {
    position: absolute;
    top: 50%;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1;
    padding: 10px;
    background: transparent;
    border: none;
    opacity: 0;
    transition: opacity 0.3s;
    display: none;
  }

  .product-card:hover .arrow-button-modal {
    opacity: 1;
    display: block;
  }

  .arrow-button-modal.left {
    left: -10px;
  }
  .arrow-button-modal.right {
    right: -10px;
  }

  .modal-arrow {
    position: absolute;
    top: 50%; /* Vertically center the arrow relative to the image */
    transform: translateY(-50%); /* Adjust to ensure perfect centering */
    font-size: 2.5rem; /* Match the size of the product card arrows */
    cursor: pointer;
    z-index: 10; /* Ensure the arrows appear on top of the image */
    padding: 10px; /* Add enough padding for better visibility */
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background for contrast */
    border-radius: 50%; /* Rounded arrows like product cards */
    border: none;
    transition: opacity 0.3s, transform 0.3s;
  }

  .modal:hover .arrow-button {
    opacity: 1;
    display: block;
  }

  .modal:hover .arrow-button-modal {
    opacity: 1;
    display: block;
  }

  .modal:hover .modal-arrow {
    opacity: 1; /* Show arrows on hover */
  }

  .modal-arrow.left {
    left: 10px; /* Position 10px from the left edge of the image */
  }

  .modal-arrow.right {
    right: 10px; /* Position 10px from the right edge of the image */
  }
  
  .modal-image-container {
    position: relative;
    overflow: hidden;
    height: 350px; /* same height as in product cards */
  }

  .modal-image-wrapper {
    display: flex;
    height: 100%;
    transition: transform 0.5s ease;
  }

  .modal-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .product-info {
    padding: 15px;
  }
  .product-title {
    font-size: 1.8rem;
    margin: 0;
    color: #333;
    font-weight: bold;
    text-align: left;
  }
  .product-description {
    color: #666;
    font-size: 1.4rem;
    margin-top: 10px;
    line-height: 1.5;
    text-align: left;
  }
  .product-price {
    font-weight: bold;
    font-size: 1.6rem;
    margin-top: 10px;
    color: ${catalog?.theme_color || '#28a745'};
    text-align: left;
  }

  .action-buttons {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 500px;
    display: flex;
    justify-content: center;
    z-index: 1000;
  }
  .checkout-button {
    flex: 1;
    padding: 15px;
    font-size: 1.3rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: ${catalog?.theme_color || '#007bff'};
    color: #fff;
  }
  .checkout-button:hover {
    font-weight: bold;
    background-color: ${catalog?.theme_color ? catalog.theme_color : '#0056b3'};
  }
  .checkout-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 15px;
    max-width: 600px;
    width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .close-modal {
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 2.5rem;
    background: none;
    border: none;
    cursor: pointer;
    transition: font-weight 0.3s ease;
  }

  .close-modal:hover {
    font-weight: bold;
  }

  .modal-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .modal-text {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
  .modal-image-wrapper {
    display: flex;
    transition: transform 0.5s ease;
  }
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .quantity-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
  }
  .quantity-button {
    background-color: ${catalog?.theme_color ? catalog.theme_color : '#0056b3'}20;
    color: ${catalog?.theme_color || '#007bff'};
    border: none;
    padding: 20px;
    font-size: 2rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  .quantity-button:hover {
    background-color: ${catalog?.theme_color ? catalog.theme_color : '#0056b3'}40;
  }
  .quantity-value {
    font-size: 2rem;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 10px;
  }
  .add-to-cart-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }
  .add-to-cart {
    background-color: ${catalog?.theme_color || '#28a745'};
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.4rem;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s;
  }
  .add-to-cart:hover {
    font-weight: bold;
    background-color: ${catalog?.theme_color ? catalog.theme_color : '#218838'};
  }
`}</style>

    </div>
  );
};

export default Shop;