import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import EditCatalogPage from './pages/EditCatalogPage';
import ClientsList from './pages/ClientsList';
import CatalogPage from './pages/CatalogPage';
import ShopPage from './pages/ShopPage';
import CheckoutPage from './pages/CheckoutPage';
import PaymentPage from './pages/PaymentPage';
import SelectedShopPage from './pages/SelectedShopPage';
import ChatSelectedShopPage from './pages/ChatSelectedShopPage';
import SkincareDecisionPage from './pages/SkincareDecisionPage';
import ConfirmationPage from './pages/ConfirmationPage';

import './globals.css'; // Add this line

const App = () => {
  const [companyId, setCompanyId] = useState(null);

  const handleSignIn = (id) => {
    setCompanyId(id);
  };

  const handleSignOut = () => {
    setCompanyId(null);
  };

  return (
    <Router>
      <MainRoutes companyId={companyId} onSignIn={handleSignIn} onSignOut={handleSignOut} />
    </Router>
  );
};

const MainRoutes = ({ companyId, onSignIn, onSignOut }) => {
  return (
    <Routes>
      <Route path="/signin" element={<SignIn onSignIn={onSignIn} />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/edit_catalog/:catalogSlug" element={<EditCatalogPage />} />
      <Route path="/clients/:companyId" element={<ClientsList onSignOut={onSignOut} />} />
      <Route path="/catalogs/:catalogSlug" element={<CatalogPage />} />
      <Route path="/shop/:catalogSlug" element={<ShopPage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/pureskin" element={<SkincareDecisionPage />} />
      <Route path="/selected_shop/:catalogSlug" element={<SelectedShopPage />} />
      <Route path="/chat_selected_shop/:catalogSlug" element={<ChatSelectedShopPage />} />
      <Route path="/confirmacao" element={<ConfirmationPage />} />
      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  );
};

export default App;