import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

const Chat = ({ color = '#333', catalogSlug }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isThinking, setIsThinking] = useState(false);
  const latestRequestRef = useRef(null); // Track the latest request

  // Initial message when the chat opens
  useEffect(() => {
    const initialMessage = {
      message: "Olá, sou Ed, seu consultor de skincare! Me conte um pouco sobre sua pele, seus objetivos, e qualquer outra coisa que ache relevante para eu te ajudar na escolha de seus produtos",
      sender: 'Ed',
      timestamp: Date.now()
    };
    setMessages([initialMessage]); // Set the initial message
  }, []);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const sendMessageToApi = async (conversation) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/chat/${catalogSlug}`, {
        params: {
          conversation: JSON.stringify(conversation),
        },
      });
      return response.data.response;
    } catch (error) {
      console.error('Error fetching bot response:', error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const userMessage = { message: newMessage, sender: 'user', timestamp: Date.now() };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages); // Update messages instantly with user input
    setNewMessage(''); // Clear the input field after sending the message
    setIsThinking(true);

    // Cancel any previous request by using a unique reference for the current request
    const currentRequest = Symbol(); // Create a unique symbol for the current request
    latestRequestRef.current = currentRequest;

    const responseMessage = await sendMessageToApi(updatedMessages);

    // Only handle the response if it's for the latest request
    if (latestRequestRef.current === currentRequest) {
      setIsThinking(false);

      if (responseMessage) {
        setMessages((prev) => [
          ...prev,
          { message: responseMessage, sender: 'Ed', timestamp: Date.now() },
        ]);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <>
      <button className={`floating-chat-button ${isOpen ? 'close-icon' : ''}`} onClick={toggleChat}>
        {isOpen ? '✕' : '💬'}
      </button>
      <div className={`chat-container ${isOpen ? 'open' : 'closed'}`}>
        {/* Chat Header */}
        <div className="chat-header">
          <img src="https://cdn-icons-png.flaticon.com/512/4712/4712109.png" alt="Ed" className="chat-header-img" />
          <div className="chat-header-info">
            <h3>Ed</h3>
            <p className="active-status">Ativo</p>
          </div>
        </div>

        {/* Chat Messages */}
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div key={index} className={`message-wrapper ${msg.sender === 'user' ? 'user-message' : 'bot-message'}`}>
              {msg.sender === 'Ed' && (
                <img src="https://cdn-icons-png.flaticon.com/512/4712/4712109.png" alt="Ed" className="message-icon" />
              )}
              <div className={`message-bubble ${msg.sender === 'user' ? 'user-bubble' : 'bot-bubble'}`}>
                <p className="message-text">{msg.message}</p>
              </div>
            </div>
          ))}
          {isThinking && (
            <div className="thinking-indicator">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          )}
        </div>

        {/* Input Field */}
        <div className="chat-input">
          <input 
            type="text" 
            value={newMessage} 
            onChange={(e) => setNewMessage(e.target.value)} 
            onKeyDown={handleKeyDown} 
            placeholder="Digite sua mensagem..." 
          />
          <button onClick={handleSendMessage}>Enviar</button>
        </div>
      </div>

      <style>
        {`
        .floating-chat-button {
          position: fixed;
          bottom: 20px;
          right: 20px;
          width: 60px;
          height: 600px;
          border-radius: 50%;
          background-color: ${color};
          color: white;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          cursor: pointer;
          z-index: 1001;
          transition: transform 0.6s ease, background-color 0.6s ease;
        }

        .floating-chat-button.close-icon {
          transform: rotate(180deg);
        }

        .chat-container {
          position: fixed;
          bottom: 0;
          right: 20px;
          width: 100%;
          max-width: 400px;
          height: 1000px;
          background-color: white;
          border: 1px solid #ccc;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          opacity: 0;
          transform: translateY(100%);
          transition: transform 0.6s ease, opacity 0.6s ease;
          pointer-events: none;
        }

        .chat-container.open {
          opacity: 1;
          transform: translateY(0);
          pointer-events: auto;
        }

        .chat-header {
          display: flex;
          align-items: center;
          padding: 10px;
          background-color: ${color};
          color: white;
          border-radius: 10px 10px 0 0;
        }

        .chat-header-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .chat-header-info h3 {
          margin: 0;
          font-size: 1.2rem;
        }

        .active-status {
          margin: 0;
          font-size: 0.9rem;
          opacity: 0.8;
        }

        .chat-messages {
          display: flex;
          flex-direction: column;
          padding: 15px;
          gap: 10px;
          overflow-y: auto;
          flex-grow: 1;
        }

        .message-wrapper {
          display: flex;
          align-items: flex-start;
        }

        .user-message {
          justify-content: flex-end;
          align-self: flex-end;
        }

        .bot-message {
          justify-content: flex-start;
        }

        .message-bubble {
          padding: 10px 15px;
          border-radius: 10px;
          max-width: 80%;
          word-wrap: break-word;
        }

        .user-bubble {
          background-color: ${color};
          color: white;
        }

        .bot-bubble {
          background-color: #f1f1f1;
          color: #333;
        }

        .message-icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .message-text {
          margin: 0;
        }

        .thinking-indicator {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
        }

        .dot {
          width: 8px;
          height: 8px;
          background-color: ${color};
          border-radius: 50%;
          margin: 0 5px;
          animation: bounce 1.2s infinite;
        }

        .chat-input {
          display: flex;
          padding: 10px;
          background-color: white;
          border-top: 1px solid #ccc;
        }

        .chat-input input {
          flex-grow: 1;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }

        .chat-input button {
          padding: 10px 15px;
          background-color: ${color};
          color: white;
          border-radius: 5px;
          border: none;
          margin-left: 10px;
        }

        @keyframes bounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-8px);
          }
        }

        @media (max-width: 768px) {
          .chat-container {
            width: 95%;
            height: 40%;
          }
        }
        `}
      </style>
    </>
  );
};

export default Chat;
