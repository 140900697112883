import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

async function getAddressByCep(cep) {
  try {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return response.data.logradouro;
  } catch (error) {
    console.error('Erro ao buscar o endereço:', error);
    return null;
  }
}

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

const getClients = async (companyId) => {
  const response = await axios.get(`${apiBaseUrl}/clients/${companyId}`);
  return response.data;
};

const getCompany = async (companyId) => {
  const response = await axios.get(`${apiBaseUrl}/companies/${companyId}`);
  return response.data;
};

const createClient = async (companyId, client) => {
  const response = await axios.post(`${apiBaseUrl}/clients/${companyId}`, client);
  return response.data;
};

const deleteClient = async (clientId) => {
  const response = await axios.delete(`${apiBaseUrl}/clients/${clientId}`);
  return response.data;
};

const getOrders = async (companyId) => {
  const response = await axios.get(`${apiBaseUrl}/orders/${companyId}`);
  return response.data;
};

const updateOrderStatus = async (orderId, status) => {
  const response = await axios.post(`${apiBaseUrl}/orders/status/${orderId}`, { status });
  window.location.reload()
  return response.data;
};

const OrdersTable = ({ companyId, orders }) => {
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    const sortedOrders = [...orders].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    setOrderList(sortedOrders);
  }, [orders]);

  const handleUpdateOrderStatus = async (orderId, status) => {
    try {
      await updateOrderStatus(orderId, status);
      setOrderList((prevOrderList) => {
        const updatedOrderList = prevOrderList.map(order => (order.id === orderId ? { ...order, status } : order));
        return updatedOrderList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      });
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  if (orderList.length === 0) return <p>No orders found for this client.</p>;

  return (
    <div className="table-container">
      <h3>Ordens</h3>
      <table>
        <thead>
          <tr>
            <th>Ações</th>
            <th>Order ID</th>
            <th>Método de pagamento</th>
            <th>Status</th>
            <th>Telefone</th>
            <th>Items</th>
            <th>Total</th>
            <th>Comissão do lucro</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {orderList.filter(order => !['preparing'].includes(order.status)).map((order) => (
            <tr key={order.id}>
              <td className="actions">
              {order.status === 'placed' ? (
                <>
                  <button className="button green" onClick={() => handleUpdateOrderStatus(order.id, 'pending-delivery')}>Confirmar pagamento</button>
                  <button className="button red" onClick={() => handleUpdateOrderStatus(order.id, 'cancelled')}>Cancelar</button>
                </>
              ) : order.status === 'pending-delivery' ? (
                <button className="button green" onClick={() => handleUpdateOrderStatus(order.id, 'delivered')}>Confirmar entrega</button>
              ) : (
                "-"
              )}
              </td>
              <td>{order.id}</td>
              <td>{order.status}</td>
              <td>{order.payment_method === 'pos' ? 'Maquininha' : order.payment_method === 'pix' ? 'PIX' : order.payment_method}</td>
              <td>{order.client_phone}</td>
              <td>{order.items.map(item => item?.name).join(', ')}</td>
              <td>R${order.items.reduce((acc, item) => acc + (item.price * item.quantity), 0)}</td>
              <td>R${order.items.reduce((acc, item) => acc + ((item.price - 1.10 * item.original_price) * item.quantity), 0).toFixed(2)}</td>
              <td>{new Date(order.created_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
        <style>{`
          .actions {
            text-align: center;
          }
          .table-container {
            overflow-x: auto;
            margin-top: 20px;
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            padding: 8px 12px;
            border: 1px solid #ddd;
            white-space: nowrap;
          }
          th {
            background-color: #f4f4f4;
          }
          .button {
            padding: 5px 10px;
            margin: 0 5px;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
          .button:hover {
            opacity: 0.8;
          }
          .button.green {
            background-color: green;
          }
          .button.green:hover {
            background-color: darkgreen;
          }
          .button.red {
            background-color: red;
          }
          .button.red:hover {
            background-color: darkred;
          }
          .button.blue {
            background-color: blue;
          }
          .button.blue:hover {
            background-color: darkblue;
          }
        `}</style>
      </table>
    </div>
  );
};

const Modal = ({ clientId, companyId, isOpen, onClose, onSubmit }) => {
  const [description, setDescription] = useState('');

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <style>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .modal {
          background: white;
          padding: 20px;
          border-radius: 5px;
          max-width: 500px;
          width: 100%;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .modal textarea {
          width: 95%;
          height: 100px;
          margin-bottom: 10px;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .button {
          padding: 10px 20px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          margin-right: 10px;
        }
        .button:hover {
          background-color: #0056b3;
        }
      `}</style>
    </div>
  );
};

const ClientsList = ({ onSignOut }) => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [clientIdModal, setClientIdModal] = useState(null);
  const [clients, setClients] = useState([]);
  const [orders, setOrders] = useState([]);
  const [company, setCompany] = useState(null);
  const [newClient, setNewClient] = useState({
    phone: '',
    email: '',
    name: '',
    zip_code: '',
    full_address: '',
  });
  const [popup, setPopup] = useState({ message: '', isError: false });
  const [summary, setSummary] = useState({ totalCommission: 0, valueToReceive: 0, valueToRepay: 0 });

  useEffect(() => {
    const fetchClients = async () => {
      const clientsData = await getClients(companyId);
      setClients(clientsData);
    };

    const fetchCompany = async () => {
      const companyData = await getCompany(companyId);
      setCompany(companyData);
    };

    const fetchOrders = async () => {
      const ordersData = await getOrders(companyId);
      setOrders(ordersData);
      calculateSummary(ordersData);
    };

    fetchClients();
    fetchCompany();
    fetchOrders();
  }, [companyId]);

  const calculateSummary = (orders) => {
    const totalCommission = orders.filter(order => !['preparing', 'cancelled'].includes(order.status)).reduce((acc, order) => {
      return acc + order.items.reduce((itemAcc, item) => itemAcc + ((item.price - 1.10 * item.original_price) * item.quantity), 0);
    }, 0);
  
    const pendingOrders = orders.filter(order => order.status === 'pending-reconciliation');
    const totalPending = pendingOrders.reduce((acc, order) => {
      return acc + order.items.reduce((itemAcc, item) => {
        const commission = (item.price - 1.10 * item.original_price) * item.quantity;
        if (order.payment_method === 'pix') {
          return itemAcc - commission;
        } else {
          return itemAcc + (item.price * item.quantity - commission);
        }
      }, 0);
    }, 0);
  
    if (totalPending >= 0) {
      setSummary({ totalCommission, valueToReceive: 0, valueToRepay: totalPending });
    } else {
      setSummary({ totalCommission, valueToReceive: -totalPending, valueToRepay: 0 });
    }
  };

  const handleCreateClient = async () => {
    try {
      const createdClient = await createClient(companyId, newClient);
      setClients([...clients, createdClient]);
      setPopup({ message: 'Cliente criado com sucesso', isError: false });
      setTimeout(() => {
        setPopup({ message: '', isError: false });
      }, 1000);
    } catch (error) {
      setPopup({ message: 'Erro ao criar cliente', isError: true });
      console.error('Erro ao criar cliente', error);
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      await deleteClient(clientId);
      setClients(clients.filter(client => client.id !== clientId));
      setPopup({ message: 'Cliente deletado com sucesso', isError: false });
      setTimeout(() => {
        setPopup({ message: '', isError: false });
      }, 1000);
    } catch (error) {
      setPopup({ message: 'Erro ao deletar cliente', isError: true });
      console.error('Erro ao deletar cliente', error);
    }
  };

  const handleSignOut = () => {
    onSignOut();
    navigate('/signin');
  };

  const handleZipCodeChange = async (e) => {
    const zip = e.target.value;
    setNewClient({ ...newClient, zip_code: e.target.value });
  
    if (zip.length === 8) {
      const addressInfo = await getAddressByCep(zip);
      setNewClient({ ...newClient, full_address: addressInfo });
    }
  };

  return (
    <div className="container">
      {popup.message && (
        <div className={`popup ${popup.isError ? 'error' : 'success'}`}>
          {popup.message}
          <button onClick={() => setPopup({ message: '', isError: false })}>Fechar</button>
        </div>
      )}
      <div className="header">
        <span className="title">Lista de Clientes</span>
        <button 
          className="button blue refresh-button" 
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
    </div>
      <ol className="client-list">
        {clients.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((client, index) => {
          const greeting = client.name ? `Olá ${client.name},` : "Olá,";
          const calling = company?.name ? `seja bem-vindo ao estoque digital ${company.name}` : "seja bem-vindo ao estoque digital";
          const catalogLink = `${company?.catalog.replace("catalogs","shop")}?name=${encodeURIComponent(client?.name)}&phone=${encodeURIComponent(client?.phone)}`;
          const message = `${greeting} ${calling} - estou compartilhando com você o nosso catálogo digital de produtos. Dá uma olhada e adicione ao seu checkout - ${catalogLink}`;
          const encodedMessage = encodeURIComponent(message);
          const whatsappLink = `https://wa.me/${client.phone}?text=${encodedMessage}`;

          return (
            <li key={client.id} className="client-item">
              {client.phone && (
                <span>
                  <strong>Telefone:</strong> {client.phone}
                </span>
              )}{' '}
              {client.name && (
                <span>
                  <strong>Nome:</strong> {client.name}
                </span>
              )}{' '}
              {client.email && (
                <span>
                  <strong>Email:</strong> {client.email}
                </span>
              )}{' '}
              {client.zip_code && (
                <span>
                  <strong>CEP:</strong> {client.zip_code}
                </span>
              )}{' '}
              {client.full_address && (
                <span>
                  <strong>Endereço:</strong> {client.full_address}
                </span>
              )}
              {client.created_at && (
                <span>
                  <strong>Criado em:</strong> {new Date(client.created_at).toLocaleDateString('pt-BR', { day: '2-digit', month: 'long', year: 'numeric' })}
                </span>
              )}
              <button
                className="button green"
                onClick={() => window.open(whatsappLink, '_blank')}
              >
                Enviar Link
              </button>
              <button
                className="button red"
                onClick={() => handleDeleteClient(client.id)}
              >
                Deletar
              </button>
            </li>
          );
        })}
      </ol>
      <div className="form">
        <h3>Criar novo cliente</h3>
        <input
          type="text"
          placeholder="Telefone (obrigatório)"
          value={newClient.phone}
          onChange={(e) => setNewClient({ ...newClient, phone: e.target.value })}
        />
        <input
          type="text"
          placeholder="Nome (opcional)"
          value={newClient.name}
          onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
        />
        <input
          type="email"
          placeholder="Email (opcional)"
          value={newClient.email}
          onChange={(e) => setNewClient({ ...newClient, email: e.target.value })}
        />
        <input
          type="text"
          placeholder="CEP (opcional)"
          value={newClient.zip_code}
          onChange={handleZipCodeChange}
        />
        <input
          type="text"
          placeholder="Endereço Completo (opcional)"
          value={newClient.full_address}
          onChange={(e) => setNewClient({ ...newClient, full_address: e.target.value })}
        />
        <button className="button blue" onClick={handleCreateClient}>Criar</button>
      </div>
      <div className="summary">
        <h3>Sumário</h3>
        <p>Total de comissão do lucro: <strong>R${summary.totalCommission.toFixed(2)}</strong></p>
        {summary.valueToReceive > 0 ? (
          <p>Valor pendente a receber: <strong>R${summary.valueToReceive.toFixed(2)}</strong></p>
        ) : (
          <p>Valor pendente a repassar: <strong>R${summary.valueToRepay.toFixed(2)}</strong></p>
        )}
      </div>
      <OrdersTable companyId={companyId} orders={orders} />
      <style>{`
        .container {
          max-width: 600px;
          margin: 0 auto;
          padding: 20px;
          background-color: #f5f5f5;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .popup {
          position: fixed;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          padding: 20px;
          background-color: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          z-index: 1000;
        }
        .popup.success {
          border: 1px solid green;
        }
        .popup.error {
          border: 1px solid red;
        }
        .popup button {
          margin-top: 10px;
        }
        .client-list {
          list-style: none;
          padding: 0;
        }
        .client-item {
          padding: 10px;
          background-color: #fff;
          border: 1px solid #ddd;
          margin-bottom: 10px;
          border-radius: 5px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow-x: auto;
          white-space: nowrap;
          gap: 20px;
        }
        .form {
          margin-top: 20px;
        }
        .form input {
          display: block;
          width: 95%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .summary {
          margin-top: 20px;
          padding: 20px;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 5px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }
        .button {
          padding: 10px 25px;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .button:hover {
          opacity: 0.8;
        }
        .button.green {
          background-color: green;
        }
        .button.green:hover {
          background-color: darkgreen;
        }
        .button.red {
          background-color: red;
        }
        .button.red:hover {
          background-color: darkred;
        }
        .button.blue {
          background-color: blue;
        }
        .button.blue:hover {
          background-color: darkblue;
        }
        .signout {
          background-color: gray;
          margin-left: 50%;
        }
        .signout:hover {
          background-color: darkgray;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .refresh-button {
          margin-left: auto;
        }
        .title {
          font-size: 1.5rem;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
};

export default ClientsList;
