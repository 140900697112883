import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

const EditCatalog = (props) => {
  const { catalogSlug: paramSlug } = useParams();
  const catalogSlug = props.catalogSlug || paramSlug;

  const [catalog, setCatalog] = useState({
    name: '',
    description: '',
    image_url: '',
    slug: '',
    old_slug: '',
  });
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [id, setId] = useState('');
  const [loadingProductIds, setLoadingProductIds] = useState([]);
  const [company, setCompany] = useState({});

  useEffect(() => {
    const fetchCatalogData = async () => {
      try {
        const catalogResponse = await axios.get(`${apiBaseUrl}/catalogs/slug/${catalogSlug}`);
        setCatalog((prevCatalog) => ({
          ...catalogResponse.data,
          old_slug: catalogResponse.data.slug,
        }));

        const productsResponse = await axios.get(`${apiBaseUrl}/products/slug/${catalogSlug}`);
        setSelectedProducts(
          productsResponse.data.map((product) => {
            const recalculatedPrice = calculateAdjustedPrice(product.original_price);
            const recalculatedLucro = product.price - recalculatedPrice;

            return {
              ...product,
              isNew: false,
              isDeleted: false,
              categories: product.categories ? product.categories.join(', ') : '',
              image_url: product.image_urls && product.image_urls.length > 0 ? product.image_urls[0] : '',
              title: product?.name || '',
              price: recalculatedPrice,
              final_price: product.price,
              lucro: recalculatedLucro >= 0 ? recalculatedLucro : 0,
            };
          })
        );

        const companyId = catalogResponse.data.company_id;
        const companyResponse = await axios.get(`${apiBaseUrl}/companies/${companyId}`);
        setCompany(companyResponse.data);
      } catch (error) {
        console.error('Error loading catalog or company data', error);
      }
    };

    fetchCatalogData();
  }, [catalogSlug]);

  const calculateAdjustedPrice = (price) => {
    const adjustedPrice = price * 1.10;
    return Math.ceil(adjustedPrice / 5) * 5;
  };

  async function getItemIdByProductId(productId) {
    try {
      const response = await axios.get(`${apiBaseUrl}/mercado-livre/recommendations`, {
        params: { product_id: productId }
      });
  
      if (response.status === 200) {
        const recommendedProducts = response.data.recommended_products;
  
        const product = recommendedProducts.find(p => p.id === productId);
  
        if (product && product.item_id) {
          return product.item_id;
        } else {
          console.log(`Item ID not found for product ${productId}.`);
        }
      } else {
        console.log(`Failed to fetch recommendations. Status Code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching data from backend:', error);
    }
  
    return productId;
  }

  async function getItemByProductId(id) {
    const itemId = await getItemIdByProductId(id);

    const url = `https://api.mercadolibre.com/items/${itemId}`;
  
    try {
      const response = await axios.get(url);
  
      if (response.status === 200) {
        const product = response.data;
        return product;
      } else {
        console.log(`Failed to fetch product. Status Code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching product information:', error);
    }
    return {};
  }

  const handleSearch = async () => {
    try {
      if (id) {
        const product = await getItemByProductId(id);

        const price = Math.ceil(product.price);
        const adjustedPrice = calculateAdjustedPrice(price);
        const defaultLucro = calculateDefaultLucro(adjustedPrice);

        setProducts([{
          id: uuidv4(),
          external_id: product?.id,
          title: product?.title,
          price: adjustedPrice,
          original_price: price,
          thumbnail: product?.thumbnail,
          internet_url: product?.permalink,
          lucro: defaultLucro,
          final_price: adjustedPrice + defaultLucro,
          isNew: true,
          isDeleted: false,
          categories: '',
          image_url: product?.thumbnail_id ? `https://http2.mlstatic.com/D_NQ_NP_2X_${product?.thumbnail_id}-F.webp` : '',
        }])
      } else {
        const response = await axios.get(`https://api.mercadolibre.com/sites/MLB/search?q=${encodeURIComponent(searchTerm)}&state=TUxCUFNBT085N2E4&city=TUxCQ1NQLTkxMjE`);

        const productResults = response.data.results.map((product) => {
          const price = Math.ceil(product.price);
          const adjustedPrice = calculateAdjustedPrice(price);
          const defaultLucro = calculateDefaultLucro(adjustedPrice);

          return {
            id: uuidv4(),
            external_id: product?.id,
            title: product?.title,
            price: adjustedPrice,
            original_price: price,
            thumbnail: product?.thumbnail,
            internet_url: product?.permalink,
            lucro: defaultLucro,
            final_price: adjustedPrice + defaultLucro,
            isNew: true,
            isDeleted: false,
            categories: '',
            image_url: product?.thumbnail_id ? `https://http2.mlstatic.com/D_NQ_NP_2X_${product?.thumbnail_id}-F.webp` : '',
          };
        });
        setProducts(productResults);
      }
    } catch (error) {
      console.error("Erro ao buscar dados no Mercado Livre", error);
    }
  };

  const calculateDefaultLucro = (basePrice) => {
    const defaultLucro = basePrice * 0.15;
    return Math.ceil(defaultLucro / 5) * 5;
  };

  const addToCatalog = async (product) => {
    if (!company.zip_code) {
      toast.error('O CEP da empresa não foi encontrado.');
      return;
    }

    setLoadingProductIds((prevIds) => [...prevIds, product.id]);

    try {
      const shippingResponse = await axios.get(`https://api.mercadolibre.com/items/${product.external_id}/shipping_options?zip_code=${company.zip_code}`);

      const deliveryDate = shippingResponse.data.options[0]?.estimated_delivery_time?.date;
      const deliveryDays = Math.max(Math.ceil(dayjs(deliveryDate).diff(dayjs(), 'hour') / 24), 1);

      const deliveryPrice = Math.ceil(shippingResponse.data.options[0]?.cost || 0);

      const productWithDeliveryInfo = {
        ...product,
        delivery_price: deliveryPrice,
        delivery_days: deliveryDays,
        original_price: product.original_price,
        price: product.price,
        final_price: product.final_price,
      };

      setSelectedProducts((prevProducts) => [...prevProducts, productWithDeliveryInfo]);
      toast.success(`${product.title} adicionado ao catálogo com sucesso!`);
    } catch (error) {
      console.error("Erro ao obter informações de entrega", error);
      toast.error("Erro ao obter informações de entrega.");
    }

    setLoadingProductIds((prevIds) => prevIds.filter((id) => id !== product.id));
  };

  const handleRemove = (id) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? { ...product, isDeleted: true }
          : product
      )
    );
  };

  const handleSave = async () => {
    const catalogId = catalog.id;
    const companyId = catalog.company_id;

    const payload = {
      catalog: {
        name: catalog.name,
        description: catalog.description,
        image_url: catalog.image_url,
        slug: catalog.slug,
        old_slug: catalog.old_slug,
      },
      products: selectedProducts.map((product) => ({
        id: product.isNew ? null : product.id,
        external_id: product.external_id,
        name: product.title,
        image_urls: [product.image_url],
        price: product.final_price,
        categories: product.categories.split(',').map((cat) => cat.trim()),
        status: product.isDeleted ? 'inactive' : 'active',
        internet_url: product.internet_url,
        original_price: product.original_price,
        discount_percentage: product.discount_percentage || 0,
        delivery_days: product.delivery_days || 0,
        delivery_price: product.delivery_price || 0,
        isNew: product.isNew,
        isDeleted: product.isDeleted,
      })),
      companyId,
    };

    try {
      const response = await axios.put(`${apiBaseUrl}/catalogs/${catalogId}`, payload);
      toast.success('Catálogo e produtos atualizados com sucesso!');

      if (payload.catalog.old_slug !== payload.catalog.slug) {
        setTimeout(() => {
          window.location.href = `/edit_catalog/${payload.catalog.slug}`;
        }, 1000);
      }
    } catch (error) {
      console.error("Erro ao salvar catálogo e produtos", error);
      toast.error("Erro ao salvar catálogo e produtos.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCatalog((prevCatalog) => ({
      ...prevCatalog,
      [name]: value,
    }));
  };

  const handleProductNameChange = (id, newName) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id ? { ...product, title: newName } : product
      )
    );
  };

  const handleLucroChange = (id, newLucro) => {
    const lucroValue = newLucro === '' ? 0 : parseInt(newLucro, 10);

    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? {
              ...product,
              lucro: lucroValue,
              final_price: product.price + lucroValue,
            }
          : product
      )
    );
  };

  const handleCategoriesChange = (id, newCategories) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id ? { ...product, categories: newCategories } : product
      )
    );
  };

  const handleDeliveryDaysChange = (id, newDeliveryDays) => {
    const deliveryDaysValue = newDeliveryDays === '' ? 0 : parseInt(newDeliveryDays, 10);
  
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id ? { ...product, delivery_days: deliveryDaysValue } : product
      )
    );
  };

  const handleOriginalPriceChange = (id, newPrice) => {
    const priceValue = newPrice === '' ? 0 : parseFloat(newPrice);

    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? {
              ...product,
              original_price: priceValue,
              price: calculateAdjustedPrice(priceValue),
              final_price: calculateAdjustedPrice(priceValue) + calculateDefaultLucro(calculateAdjustedPrice(priceValue)),
            }
          : product
      )
    );
  };

  return (
    <div className="mercado-container">
      <h1>Editar catálogo</h1>

      <div className="catalog-edit-fields">
        <div className="input-field">
          <label htmlFor="name">Nome:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={catalog?.name}
            onChange={handleInputChange}
            placeholder="Nome do catálogo"
            className="catalog-input"
          />
        </div>

        <div className="input-field">
          <label htmlFor="description">Descrição:</label>
          <input
            type="text"
            id="description"
            name="description"
            value={catalog?.description}
            onChange={handleInputChange}
            placeholder="Descrição do catálogo"
            className="catalog-input"
          />
        </div>

        <div className="input-field">
          <label htmlFor="image_url">URL da Imagem:</label>
          <input
            type="text"
            id="image_url"
            name="image_url"
            value={catalog?.image_url}
            onChange={handleInputChange}
            placeholder="URL da imagem do catálogo"
            className="catalog-input"
          />
        </div>

        <div className="input-field">
          <label htmlFor="slug">Slug:</label>
          <input
            type="text"
            id="slug"
            name="slug"
            value={catalog?.slug}
            onChange={handleInputChange}
            placeholder="Slug do catálogo"
            className="catalog-input"
          />
        </div>
      </div>

      <div className="selected-products">
        <h3>Produtos:</h3>
        {selectedProducts.length === 0 ? (
          <p>Não há produtos nesse catálogo</p>
        ) : (
          <>
            <div className="products-header">
              <div className="header-item header-image"></div>
              <div className="header-item header-name">Nome</div>
              <div className="header-item header-price">Preço + Lucro = Final</div>
              <div className="header-item header-categories">Categorias</div>
              <div className="header-item header-delivery">Entrega</div>
              <div className="header-item header-remove"></div>
            </div>
            <div className="cart-items">
              {selectedProducts.filter(product => !product.isDeleted).map((product) => (
                <div key={product?.id} className="cart-item">
                  <img src={product?.image_url} alt={product?.title} className="cart-item-image" />
                  <div className="cart-item-details">
                    <input
                      type="text"
                      value={product?.title}
                      onChange={(e) => handleProductNameChange(product.id, e.target.value)}
                      className="product-name-input"
                      style={{ width: '150px' }}
                    />
                  </div>
                  <div className="item-price">
                    R$ {product?.price?.toFixed(2)} +{' '}
                    <input
                      type="text"
                      value={product?.lucro?.toFixed(2) || ''}
                      onChange={(e) => handleLucroChange(product.id, e.target.value)}
                      placeholder="lucro"
                      className="profit-input"
                      min="0"
                    />{' '}
                    = R$ {product?.final_price?.toFixed(2)}
                  </div>
                  <div className="item-categories">
                    <input
                      type="text"
                      value={product?.categories || ''}
                      onChange={(e) => handleCategoriesChange(product.id, e.target.value)}
                      className="categories-input"
                      placeholder="Categorias separadas por vírgula"
                    />
                  </div>
                  <div className="delivery-info">
                    <input
                      type="text"
                      value={product?.delivery_days || ''}
                      onChange={(e) => handleDeliveryDaysChange(product.id, e.target.value)}
                      className="delivery-input"
                      placeholder="Entrega (dias)"
                    />
                  </div>
                  <button className="remove-button" onClick={() => handleRemove(product?.id)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <button
        onClick={handleSave}
        className="mercado-button save-button"
      >
        Salvar
      </button>

      <div className="search-bar">
        <input
          type="text"
          placeholder="Buscar produto"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          className="mercado-input"
        />
        <button onClick={handleSearch} className="small-button">Buscar</button>
      </div>
      <div className="id-bar">
        <input
          type="text"
          placeholder="Buscar id"
          value={id}
          onChange={(e) => setId(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          className="mercado-input"
        />
        <button onClick={handleSearch} className="small-button">Buscar id</button>
      </div>

      <div className="product-list">
        {products.map((product) => (
          <div key={product?.id} className="product-item">
            <img src={product?.thumbnail} alt={product?.title} className="product-img-left" />
            <div className="product-info">
              <h2>{product?.title}</h2>
              <p>
                Preço api: R$
                <input
                  type="text"
                  value={product?.original_price?.toFixed(2)}
                  onChange={(e) => handleOriginalPriceChange(product.id, e.target.value)}
                  className="price-input"
                />
              </p>
              <p>Preço base: R$ {product?.price?.toFixed(2)}</p>
              <a href={product?.internet_url} target="_blank" rel="noreferrer">
                Ver no Mercado Livre
              </a>
              <br/>
              <br/>
              <button
                onClick={() => addToCatalog(product)}
                className="mercado-button"
                disabled={loadingProductIds.includes(product.id)}
              >
                {loadingProductIds.includes(product.id) ? 'Carregando...' : 'Adicionar ao catálogo'}
              </button>
            </div>
          </div>
        ))}
      </div>

      <style>{`
        .mercado-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          width: 100%;
          max-width: 600px;
          margin: 40px auto 0 auto;
        }
        h1 {
          margin-bottom: 20px;
        }
        .catalog-edit-fields {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
        }
        .input-field {
          display: flex;
          flex-direction: column;
        }
        .input-field label {
          margin-bottom: 5px;
        }
        .catalog-input {
          padding: 10px;
          border-radius: 4px;
          border: 1px solid #ddd;
          width: 100%;
        }
        .selected-products {
          width: 100%;
          padding: 10px;
          background: white;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          margin-bottom: 10px;
          overflow-x: auto;
        }
        .products-header, .cart-item {
          display: grid;
          grid-template-columns: 80px 2fr 1fr 1fr auto 40px; /* Set auto width for delivery-info */
          align-items: center;
          padding: 10px 0;
          gap: 20px;
          min-width: 800px; /* Ensures that the container scrolls if contents exceed this width */
          white-space: nowrap;
        }
        .products-header {
          border-bottom: 2px solid #ddd;
          font-weight: bold;
        }
        .cart-item {
          border-bottom: 2px solid #ddd;
          padding: 10px 0;
          width: 100%;
        }
        .cart-item-image {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 5px;
        }
        .header-name, .header-price, .header-delivery, .cart-item-details, .item-price {
          font-weight: bold;
        }
        .product-name-input {
          width: 100%;
          padding: 5px;
          border: 1px solid #ddd;
          border-radius: 4px;
        }
        .profit-input {
          width: 60px;
          padding: 5px;
          border: 1px solid #ddd;
          border-radius: 4px;
          margin-left: 5px;
        }
        .price-input {
          width: 80px;
          padding: 5px;
          border: 1px solid #ddd;
          border-radius: 4px;
          margin-left: 5px;
        }
        .remove-button {
          background-color: transparent;
          color: #333;
          border: 1px solid #ddd;
          padding: 8px 12px;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
        }
        .remove-button:hover {
          background-color: #ddd;
        }
        .save-button {
          margin-top: 10px;
          background-color: #007bff;
        }
        .save-button:hover {
          background-color: #0056b3;
        }
        .search-bar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 10px;
          margin-top: 40px;
        }
        .id-bar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 10px;
        }
        .mercado-input {
          width: 80%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
        }
        .small-button {
          padding: 8px 12px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          text-transform: lowercase;
          transition: background-color 0.3s ease;
        }
        .small-button:hover {
          background-color: #0056b3;
        }
        .product-list {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
        }
        .product-item {
          background: white;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          text-align: left;
        }
        .product-img-left {
          width: 100px;
          height: 100px;
          object-fit: cover;
          margin-right: 20px;
          border-radius: 8px;
        }
        .product-info {
          flex: 1;
        }
        .mercado-button {
          width: 100%;
          padding: 10px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          text-transform: lowercase;
          transition: background-color 0.3s ease;
        }
        .mercado-button:hover {
          background-color: #0056b3;
        }
      `}</style>
      <ToastContainer />
    </div>
  );
};

export default EditCatalog;
