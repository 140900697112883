import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { restartUrl, themeColor } = location.state || {};

  const handleOpenRestartModal = () => {
    if (restartUrl) {
      navigate(restartUrl);
    }
  };

  return (
    <div className="confirmation-container">
      <div className="check-icon">✓</div>
      <h1 style={{ fontSize: '2.5rem', marginTop: '20px' }}>Obrigado!</h1>
      <p style={{ fontSize: '1.5rem', marginBottom: '40px' }}>Manteremos você informado sobre o pedido pelo WhatsApp.</p>
      <ul style={{ fontSize: '1.2rem', lineHeight: '1.6', marginBottom: '40px' }}>
        <li>Seu pagamento foi confirmado com sucesso</li>
        <li>Seus produtos estão sendo preparados para envio (prazo de até 1 semana)</li>
        <li>Estamos à disposição no número (11) 4160-2691 para eventuais dúvidas</li>
      </ul>

      <div>
        <button className="recomecar-button" onClick={handleOpenRestartModal}>
          <span className="recomecar-text">↺ Recomeçar</span>
        </button>
      </div>

      <style>{`
        .confirmation-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100vh;
          text-align: center;
          font-family: Arial, sans-serif;
          padding: 0 20px;
        }
        .check-icon {
          color: ${themeColor || 'green'};
          font-size: 10rem;
          margin-bottom: 20px;
        }
        h1 {
          font-size: 2.5rem;
          margin-bottom: 10px;
        }
        p {
          font-size: 1.25rem;
          margin-bottom: 20px;
        }
        ul {
          list-style-type: none;
          padding: 0;
          text-align: left;
          font-size: 1.2rem;
          line-height: 1.6;
        }
        ul li {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
        }
        ul li::before {
          content: '•';
          color: green;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-right: 0.5em;
        }
        .recomecar-button {
          padding: 12px 25px;
          background-color: ${themeColor || '#333'};
          color: #fff;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          font-size: 1.2rem;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.3s;
          margin-top: 20px;
        }
        .recomecar-button:hover {
          background-color: ${themeColor || '#333'}70; /* Change border on hover */
          font-weight: bold;
        }
        .recomecar-text {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </div>
  );
};

export default ConfirmationPage;
