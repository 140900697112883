import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

// API Function for signing in
const signIn = async (email, password) => {
  return await axios.post(`${apiBaseUrl}/signin`, { email, password });
};

const SignIn = ({ onSignIn }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [popup, setPopup] = useState({ message: '', isError: false });

  const handleSignIn = async () => {
    try {
      const response = await signIn(email, password);
      setPopup({ message: 'Logado com sucesso', isError: false });
      onSignIn(response.data.id);
      navigate(`/clients/${response.data.id}`);
    } catch (error) {
      setPopup({ message: 'Erro ao fazer login', isError: true });
      console.error('Erro ao fazer login', error);
    }
  };

  const closePopup = () => {
    setPopup({ message: '', isError: false });
  };

  return (
    <div className="container">
      {popup.message && (
        <div className={`popup ${popup.isError ? 'error' : 'success'}`}>
          {popup.message}
          <button onClick={closePopup}>Fechar</button>
        </div>
      )}
      <div className="form-container">
        <h2>Entrar</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleSignIn}>Entrar</button>
        <p>
          Não tem uma conta?{' '}
          <span
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => navigate('/signup')}
          >
            Cadastre-se
          </span>
        </p>
      </div>
      <style>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
          background-color: #f0f2f5;
          padding: 20px;
        }
        .form-container {
          background: white;
          padding: 30px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 400px;
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        .form-container input {
          width: 95%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
        }
        .form-container button {
          padding: 10px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .form-container button:hover {
          background-color: #0056b3;
        }
        .popup {
          position: fixed;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          padding: 20px;
          background-color: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          z-index: 1000;
        }
        .popup.success {
          border: 1px solid green;
        }
        .popup.error {
          border: 1px solid red;
        }
        .popup button {
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
};

export default SignIn;
